<template>
  <form>
    <h3>EVALUACIÓN DE RIESGO</h3>
        
    <label for="riskType">Tipo de riesgo</label>
    <select v-model="data.newRiskType" id="riskType">
        <option disabled value="">Seleccione un tipo de riesgo</option>
        <option value="inherent">Inherente</option>
        <option value="residual">Residual</option>
    </select>
    <label for="index_a">Índice de personas expuestas</label>
    <select v-model="data.index_a" id="index_a">
        <option value="1">Moderado</option>
        <option value="2">Alto</option>
        <option value="3">Muy Alto</option>
    </select>
    <label for="index_b">Índice de procedimientos existentes</label>
    <select v-model="data.index_b" id="index_b">
        <option value="1">Moderado</option>
        <option value="2">Alto</option>
        <option value="3">Muy Alto</option>
    </select>
    <label for="index_c">Índice de capacitación</label>
    <select v-model="data.index_c" id="index_c">
        <option value="1">Moderado</option>
        <option value="2">Alto</option>
        <option value="3">Muy Alto</option>
    </select>
    <label for="index_d">Índice de expocisión al riesgo</label>
    <select v-model="data.index_d" id="index_d">
        <option value="1">Moderado</option>
        <option value="2">Alto</option>
        <option value="3">Muy Alto</option>
    </select>
    <label for="severity">Índice de severidad</label>
    <select v-model="data.severity" id="severity">
        <option value="1">Moderado</option>
        <option value="2">Alto</option>
        <option value="3">Muy Alto</option>
    </select>
    <label class="chekbox" for="signification">
        <input type="checkbox" id="signification" v-model="data.signification"/>
        ¿Es significativo?</label>
        
    <div class="botones">
      <button type="button" @click="showIPSSOBOTModalMethod">
        <img src="@/assets/img/expandirchat.png" alt="" /> Preguntar a
        IPSSOBOT
      </button>
      <button type="button" @click="submitEvaluationRisk" :disabled="!isFormEvalFilled">Guardar</button>
    </div>
    <IPSSOBOTModal
        :isOpen="showIPSSOBOTModal"
        @closeModal="showIPSSOBOTModal = $event"
        :chatGptPrompt="chatGptPrompt"
    />
    
  </form>
   
</template>

<script>
import IPSSOBOTModal from "./IPSSOBOTModal.vue";
export default {
    name: "EvaluationForm",
    props: {
      cleanAll: Boolean,
      risk: Number,
    },
    components: {
        IPSSOBOTModal
    },
    emits: ['risk-evaluation'],
    data() {
        return {
            showIPSSOBOTModal: false,
            chatGptPrompt: '',
            riskId: 0,
            data: {
                newRiskType: '',
                index_a: 1,
                index_b: 1,
                index_c: 1,
                index_d: 1,
                severity: 1,
                signification: false,
            }
        };
    },
    methods: {
        clearInputsData() {
          this.data = {
            newRiskType: '',
            index_a: 1,
            index_b: 1,
            index_c: 1,
            index_d: 1,
            severity: 1,
            signification: false
          };
        },
        submitEvaluationRisk(){
            this.$emit('risk-evaluation', this.data)
        },
        showIPSSOBOTModalMethod() {
            this.showIPSSOBOTModal = true;
            this.getRisk(this.riskId);
        },
        createPrompt(risk){
          if (this.isFormEvalFilled){
            this.chatGptPrompt = "Analizar las siguientes dos variables para hacer una sugerencia de evaluación de riesgo bajo el esquema SSOMA: \n"
            +"Riesgo asociado: "+risk+"\nTipo de riesgo: "+ (this.data.newRiskType === "inherent" ? "inherente" : "residual") +
            "\nSugerir la evaluación de riesgo con los siguientes parámetros de salida: índice de personas expuestas, indice de procedimientos existentes, "+
            "indice de capacitación, índice de exposición al riesgo y el índice de serveridad. Solo imprime desde sugerencias desde indice de personas expuestas hasta el indice de severidad, omite el mensaje inicial";
          }
          
        },
        async getRisk(id){
          try{
            const response = await this.$axios.get(`/chatbot/risk/${id}`);
            this.createPrompt(response.data.description);
          } catch(error){
            console.error('Error getting risk:', error);
          }
        },
    },
    computed:{
        isFormEvalFilled(){
            return this.data.newRiskType !== '';
        },
    },
    watch:{
      cleanAll(value) {
        if (value) {
          this.clearInputsData();
        }
      },
      risk(value) {
        this.riskId = value;
      }
    },
};
</script>
<style scoped>
label {
  margin-bottom:0;
}
/* matriz iperc */
.matriz-iperc .ingresar-datos {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.ingresar-datos input {
  border: 1.5px solid #f8bc02;
  border-radius: 5px;
}

.ingresar-datos input:focus {
  outline: none;
}

.ingresar-datos input::placeholder {
  font-family: "Anek Latin", sans-serif;
  font-weight: 300;
  font-size: 15px;
  text-align: left;
  color: #b9b9b9;
  padding: 0px 10px;
}

.matriz-iperc .ingresar-datos input:nth-child(1) {
  width: 220px;
  height: 30px;
  margin: 2px;
}

.matriz-iperc .ingresar-datos input:nth-child(2) {
  width: 108px;
  height: 30px;
  margin: 2px;
}

.ingresar-proceso {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.ingresar-proceso button {
  font-family: "Anek Latin", sans-serif;
  font-weight: 600;
  font-size: 20px;
  border: 1.5px solid #f8bc02;
  background: #ffffff;
  color: #f8bc02;
  border-radius: 8px;
  cursor: pointer;
}

.ingresar-proceso button:nth-child(1) {
  width: 154.85px;
  height: 40.4px;
  margin: 2px;
}

.ingresar-proceso button:nth-child(2) {
  width: 38.47px;
  height: 40.4px;
  margin: 2px;
  font-weight: 800;
}

/* modal de nombre de proceso */
.modal-content {
  background: #f8bc02;
  width: 306px;
  height: 182px;
  text-align: center;

  border-radius: 20px;
  display: flex;
  border: 3px solid white;
  padding: 13px 0px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: space-between;
}

.modal-content h2 {
  font-size: 15px;
  font-family: "Anek Latin", sans-serif;
  font-weight: 500;
  color: white;
  width: 140px;
  height: auto;
  margin-top: 11px;
  text-align: center;
}

.modal-content input {
  border: 1px solid #b9b9b9;
  width: 254px;
  height: 40px;
  border-radius: 10px;
  margin-top: -41px;
}

.modal-content input::placeholder {
  font-family: "Anek Latin", sans-serif;
  text-align: center;
  font-size: 20px;
  color: black;
  font-weight: 700;
}

.modal-content input:focus {
  outline: none;
}

.modal-content button {
  border: 2px solid white;
  background: #f8bc02;
  margin-top: 21px;
  width: 93px;
  height: 30px;
  border-radius: 10px;
  color: white;
  font-family: "Anek Latin", sans-serif;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  margin-top: -40px;
}

/* vista de las actividades */
.actividades-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 363px;
  position: relative;
}

.actividades-container div {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.actividades-container h2 {
  font-family: "Anek Latin", sans-serif;
  font-weight: 600;
  font-size: 15px;
  margin: 3px;
}

.actividades-container .btn-agregar-actividad {
  margin: 3px;
  border: 1px solid #f8bc02;
  border-radius: 10px;
  background: white;
  font-weight: 900;
  color: #f8bc02;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.actividades-container .actividades-table {
  margin-top: 14px;
}

.actividades-container .actividades-table td {
  border: 1px solid #f8bc02;
}

.actividades-container .actividades-table td:nth-child(1) {
  width: 276px;
  height: 26px;
  text-align: center;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 700;
}

.actividades-container .actividades-table button:hover {
  background: #f8bc02;
}

.actividades-container .btn-editar {
  width: 26px;
  height: 26px;
  background: white;
  border: none;
  cursor: pointer;
}

.actividades-container .btn-editar img {
  width: 13.13px;
  height: 15.01px;
}

.actividades-container .btn-ejecutar {
  background: #f8bc02;
  position: absolute;
  bottom: 35px;
  border-radius: 5px;
  color: #fff;
  width: 204px;
  height: 40px;
  font-family: "Anek Latin", sans-serif;
  font-size: 20px;
  font-weight: 600;
  opacity: 0.5;
  /* pointer-events: none;
  cursor: not-allowed; */
  border: none;
}

.actividades-container .btn-eliminar {
  background: white;
  border: none;
  cursor: pointer;
  width: 26px;
  height: 26px;
}

.actividades-container .btn-eliminar img {
  width: 13.13px;
  height: 13.13px;
}

/* registro de editar */
.nuevo-contenedor {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Anek Latin", sans-serif;
  font-size: 15px;
  margin-top: -20px;
}

.nuevo-contenedor .btn-regresar {
  width: 338px;
  display: flex;
  font-family: "Anek Latin", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #f8bc02;
  background: white;
  border: none;
  justify-content: flex-start;
  margin: 12px 0px;
  cursor: pointer;
}

.nuevo-contenedor .btn-regresar img {
  margin-right: 8px;
}

.nuevo-contenido {
  font-family: "Anek Latin", sans-serif;
  font-size: 15px;
}

.nuevo-contenido button{
  width: 161px;
  height: 48px;
  font-family: "Anek Latin", sans-serif;
  font-size: 15px;
  color: white;
  background: #f8bc02;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  padding: 0px 20px;
  margin-bottom: 10px;
  margin-right: 4px;
  cursor: pointer;
}

.nuevo-contenido button:disabled {
  opacity: 0.5;
}

.nuevo-contenido form {
  display: flex;
  flex-direction: column;
  width: 332px;
  line-height: 22px;
  text-align: left;
}

.nuevo-contenido form input {
  width: 332px;
  height: 25px;
  background: #eeeeee;
  border: 1px solid #ababab;
  border-radius: 4px;
}

.nuevo-contenido input::placeholder {
  font-family: "Anek Latin", sans-serif;
  font-weight: 300;
  font-size: 15px;
  text-align: left;
  color: #656565;
  padding: 0px 10px;
}

.nuevo-contenido form input:focus {
  outline: none;
}

.nuevo-contenido form label {
  font-weight: 600;
}

.nuevo-contenido .lugar-posicion {
  display: flex;
}

.nuevo-contenido .lugar-posicion .lugar {
  margin-right: 10px;
}

.nuevo-contenido .lugar-posicion select {
  width: 161px;
  height: 25px;
  background: #eeeeee;
  border: 1px solid #ababab;
  border-radius: 4px;
}

.nuevo-contenido select:focus {
  outline: none;
}

.nuevo-contenido .lugar-posicion input {
  width: 161px;
  height: 25px;
}

.nuevo-contenido .tipo {
  width: 332px;
  height: 25px;
  background: #eeeeee;
  border: 1px solid #ababab;
  border-radius: 4px;
}

.nuevo-contenido form .enviar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 9px;
}

.nuevo-contenido .enviar button {
  height: 40px;
  width: 121px;
}

.nuevo-contenido .enviar button:disabled {
  opacity: 0.5;
}


.nuevo-contenido .enviar label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.nuevo-contenido .enviar input {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

/* formulario riesgo */
.formulario-riesgo h3 {
  font-family: "Anek Latin", sans-serif;
  font-weight: 800;
  font-size: 15px;
  margin: 0px;
}

.formulario-riesgo label {
  font-family: "Anek Latin", sans-serif;
  font-weight: 600;
  font-size: 15px;
}

.formulario-riesgo select {
  width: 332px;
  height: 25px;
  background: #eeeeee;
  border: 1px solid #ababab;
  border-radius: 4px;
  margin-bottom: 10px;
}

.formulario-riesgo .chekbox {
  display: flex;
  position: relative;

  justify-content: center;
}

.formulario-riesgo .chekbox input {
  width: 14px;
  height: 14px;
  top: 4px;
}

.formulario-riesgo .botones {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formulario-riesgo .botones button {
  width: 121px;
  height: 40px;
}

.formulario-riesgo .botones button:nth-child(1) {
  background: white;
  color: black;
  display: flex;
  padding: 5px;
  border: 3px solid #f8bc02;
  align-items: center;
}

.formulario-riesgo .botones button:nth-child(2) {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.formulario-riesgo .botones button:nth-child(2).disabled {
  opacity: 0.5;
}

input::after{
  content: none;
}
label[for="signification"]{
  display: flex;
  column-gap: 10px;
}
input[type="checkbox"]{
  position: relative;
  margin: 0 0;
}
</style>