<template>
    <form>
        <h3>CONTROL OPERATIVO</h3>
        <div class="form-container">

            <div class="form-field">
                <label for="eliminacion">Eliminación:</label>
                <textarea type="text" id="eliminacion" v-model="data.eliminacion" ></textarea>
            </div>

            <div class="form-field">
                <label for="sustitucion">Sustitución:</label>
                <textarea type="text" id="sustitucion" v-model="data.sustitucion" ></textarea>
            </div>

            <div class="form-field">
                <label for="controlIngenieria">Control de ingeniería:</label>
                <textarea type="text" id="controlIngenieria" v-model="data.controlIngenieria" ></textarea>
            </div>

            <div class="form-field">
                <label for="controlAdministrativo">Control administrativo:</label>
                <textarea type="text" id="controlAdministrativo" v-model="data.controlAdministrativo" ></textarea>
            </div>

            <div class="form-field">
                <label for="EPPs">EPPs:</label>
                <textarea type="text" id="EPPs" v-model="data.EPPs" ></textarea>
            </div>

            <button type="button" class="submit-button" @click="submitData" :disabled="!isFormFilled">ENVIAR</button>
        </div>
    </form>

</template>

<script>
export default {
    emits: ['operational-control'],
    props: {
      cleanAll: Boolean,
    },
    data() {
        return {
            data: {
                eliminacion: '',
                sustitucion: '',
                controlIngenieria: '',
                controlAdministrativo: '',
                EPPs: ''
            }
        };
    },
    methods: {
        clearInputsData() {
          this.data = {
            eliminacion: '',
            sustitucion: '',
            controlIngenieria: '',
            controlAdministrativo: '',
            EPPs: ''
          };
        },
        submitData() {
            this.$emit('operational-control',this.data)
        }
    },
    computed :{
        isFormFilled() {
            return this.data.eliminacion !== '' && this.data.sustitucion !== '' && this.data.controlIngenieria !== '' && this.data.controlAdministrativo !== '' && this.data.EPPs !== '';
        }
    },
    watch:{
      cleanAll(value) {
        if (value) {
          this.clearInputsData();
        }
      },
    }
};
</script>

<style scoped>
.form-container {
    width: 332px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2px;
    font-family: Arial, sans-serif;
}

.form-field {
    display: flex;
    flex-direction: column;

}

label {

    color: black;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    margin-bottom:0;

}

input[type="text"] {
    width: 332px;
    height: 47px;
    background-color: #EEEEEE;
    border: 1px solid #ABABAB;
    border-radius: 3px;
      
}

textarea {
    width: 332px;
    height: 47px;
    background-color: #EEEEEE;
    border: 1px solid #ABABAB;
    border-radius: 3px;
    resize: vertical; /* Permite al usuario cambiar la altura verticalmente */
}
form{
    margin-bottom: 15px;
}
.submit-button {
    background-color: #F8BC02;
    width: 121px;
    height: 40px;
    color: #FFF;
    font-weight: 600;
    font-size: 20px;
    padding: 10px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    text-transform: uppercase;

}

.submit-button:hover {
    background-color: #e0a800;
}

.submit-button:disabled {
    opacity: 0.5;
}

h3 {
    color: #000;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
    margin: 0;
}
</style>