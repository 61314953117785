<template>
  
  <div class="matriz-container">

    <div class="form-group">
      <div>
        <input type="text" :value="newProcessName" placeholder="Nombre del proceso" class="form-control process" readonly/>
        <input type="text" :value="newRUC" placeholder="RUC" class="form-control ruc" readonly/>
      </div>
      <input type="text" :value="newCorporation" placeholder="Razón social" class="form-control" readonly/>
    </div>


    <component :is="selectedComponent" :matrix_id="matrix_id"></component>
    <button class="ipso-reco" @click="showIPSSOBOTModalMethod"><img :src="require('@/assets/img/expand-recommend.svg')" alt="expand-recommendation"><span
        class="expand-reco">IPSSOBOT te recomienda ...</span></button>
    <div class="export-container">
      <button class="red-button" @click="exportarPDF(matrix_id)">Exportar PDF</button>
      <a class="green-button" :href="`https://crm.ipssoma.com/api/v1/public/matrix/export/excel/${matrix_id}`" target="_blank">Exportar Excel</a>
    </div>
    <div class="select-container">
      <select v-model="selectedOption" @change="loadOperationalControls">
        <option v-for="option in ipercs" :value="option.id" :key="option.id">
          {{ option.risk.description }}
        </option>
      </select>
      <div class="select-arrow"></div>
    </div>
    <div class="suggestion-container" v-if="operationalControls">
      <span style="font-weight: bold;">Controles de operación</span>
      <div class="table-container">
        <table>
          <tbody>
            <tr>
              <th>Eliminación:</th>
              <td>{{ operationalControls[0].elimination }}</td>
            </tr>
            <tr>
              <th>Sustitución:</th>
              <td>{{ operationalControls[0].sustitution }}</td>
            </tr>
            <tr>
              <th>Control de Ingeniería:</th>
              <td>{{ operationalControls[0].engineering_control }}</td>
            </tr>
            <tr>
              <th>Control Administrativo:</th>
              <td>{{ operationalControls[0].admin_control }}</td>
            </tr>
            <tr>
              <th>EPPs:</th>
              <td>{{ operationalControls[0].epps }}</td>
            </tr>
          </tbody>
        </table>
      </div>    
    </div>
    <IPSSOBOTModal
      :isOpen="showIPSSOBOTModal"
      @closeModal="showIPSSOBOTModal = $event"
      :chatGptPrompt="prompt"
    />
  </div>
</template>

<script>
import IpercTabla from './IpercTabla.vue';
import IPSSOBOTModal from './IPSSOBOTModal.vue';
export default {
  name: 'IpercResult',
  components: {
    IpercTabla,
    IPSSOBOTModal
  },
  props: {
    matrix_id: Number,
    newProcessName: String,
    newRUC: String,
    newCorporation: String,
  },
  data() {
    return {
      showIPSSOBOTModal: false,
      selectedComponent: 'IpercTabla',
      ipercs: null,
      selectedOption: null,
      operationalControls: null,
      prompt: '',
    };
  },
  mounted() {
    this.getMatrix();
  },
  methods: {  
    showIPSSOBOTModalMethod() {
      this.showIPSSOBOTModal = true;
    },
    loadOperationalControls() {
      const selectedIperc = this.ipercs.find(iperc => iperc.id === this.selectedOption);
      if (selectedIperc) {
        this.operationalControls = selectedIperc.operational_controls;
        //console.log("Operational Controls:", this.operationalControls);
        this.prompt = this.createPrompt(this.getData(selectedIperc));
        //console.log("Prompt para ChatGPT:", this.prompt);
      } else {
        this.operationalControls = null;
      }
    },
    getMatrix(){
      try{
        console.log('Obteniendo matriz con ID:', this.matrix_id);
        this.$axios.get(`/chatbot/matrix/${this.matrix_id}`)
          .then(response => {
            this.razonSocial = response.data.corporation;
            this.ruc = response.data.ruc;
            this.ipercs = response.data.iperc;
            this.selectedOption = this.ipercs.length > 0 ? this.ipercs[0].id : null;
            this.loadOperationalControls();
          })
          .catch(error => { 
            console.error('Error getting matrix:', error);
          });
        
      } catch(error){
        console.error('Error getting matrix:', error);
      }
    },
    createPrompt(data){
      return `Analizar las siguientes variables para hacer sugerencias de control bajo el esquema SSOMA. Toma en cuenta que la sugerencia
        por lo menos debe incluir una estructura de eliminación , sustitución, control de ingeniería , control de adminstración y epps.\n\n
        Nombre del proceso: ${data.process}\n\n
        Actividad asociada: ${data.task}\n\n
        Lugar de la actividad: ${data.place}\n\n
        Posición de trabajo encargada: ${data.work_position}\n\n
        Peligro asociado: ${data.danger}\n\n
        Riesgo asociado: ${data.risk}\n\n
        Tipo de riesgo: ${data.iperc_type === 'inherent' ? 'inherente': 'residual'}\n\n
        Consecuencia asociado: ${data.consequence}\n\n
        Es rutinario: ${data.iperc_routine === 'Y' ? 'Sí' : 'No'}\n\n
        Tipo de riesgo: ${data.risk_evaluation[0].type}\n\n
        Probabilidad: ${data.risk_evaluation[0].probability}\n\n
        Severidad: ${data.risk_evaluation[0].severity}\n\n
        Es significativo?: ${data.risk_evaluation[0].signification === 1 ? 'Sí' : 'No'}\n\n
        Solo responde eliminación , sustitución, control de ingeniería , control de adminstración y epps sugeridas`;
    },
    getData(iperx) {
      return {
        risk: iperx.risk.description,
        place: iperx.place.name,
        process: iperx.process.name,
        task: iperx.task.description,
        work_position: iperx.work_position.name,
        danger: iperx.danger.description,
        consequence: iperx.consequence.description,
        iperc_routine: iperx.routine,
        iperc_type: iperx.type,
        risk_evaluation : [
          {
            type: iperx.risk.risk_evaluation[0].type,
            probability: iperx.risk.risk_evaluation[0].probability,
            severity: iperx.risk.risk_evaluation[0].severity,
            signification: iperx.risk.risk_evaluation[0].signification,
          }
        ]
      };
    },
    async exportarPDF(id) {
      try {
        // Realizar la solicitud GET a la API
        const response = await this.$axios.get(`/chatbot/matrix/export/pdf/${id}`, {
        responseType: 'blob', // Establecer el tipo de respuesta como blob (archivo)
        });
        
        // Crear una URL de objeto de archivo para el blob recibido
        const url = window.URL.createObjectURL(new Blob([response.data]));
        
        // Crear un enlace HTML para descargar el archivo
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `iperc_${id}.pdf`); // Establecer el nombre del archivo
        
        // Hacer clic en el enlace para descargar el archivo
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error al exportar en PDF:', error);
      }
    }
  },
  computed: {
    chatGptPrompt() {
      if (this.getSelectedOption()) {
        const data = this.getData(this.selectedOption.id);
        return this.createPrompt(data);
      } else {
        return ''; 
      }
    },
  },
}

</script>

<style scoped>

.matriz-container {
  display: flex;
  position: relative;
  width: 402px;
  flex-direction: column;
  row-gap: 10px;
  max-height: 450px;
  overflow-y: auto;
  padding: 0px 32px;
}

.company-info {
  display: flex;

}

.add-matrix {
  display: flex;
}

.activities {
  display: flex;
}

.radio-button {
  width: 100px;
  height: 50px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #F8BC02;
  color: white;
  font-weight: bold;
}

.selected {
  background-color: #AD8700;
}

.matriz-toggle {
  display: flex;
  justify-content: center;
  column-gap: 10px;
}

.export-container {
  display: flex;
  justify-content: center;
  column-gap: 5px;
}

.red-button,
.green-button {
  color: #FFFFFF;
  /* White */
  border: none;
  border-radius: 10px;
  /* Large border radius for round corners */
  /* Padding for size */
  font-size: 15px;
  /* Text size */
  font-weight: 700;
  cursor: pointer;
  /* Cursor style */
  text-decoration: none;
  /* Remove underline */
  width: 164px;
  height: 31px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Red Button */
.red-button {
  background-color: #FF0000;
  /* Red */

}

/* Green Button */
.green-button {
  background-color: #008000;
  /* Green */

}


.expand-reco {
  color: black;
  font-weight: 700;

}

.ipso-reco {
  display: flex;
  align-self: center;
  column-gap: 5px;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-group div {
  display: flex;
  column-gap: 5px;
}

.process {
  width: 65%;
}

.ruc {
  width: 35%
}

.form-control {
  padding: 10px;
  border: 1px solid #F8BC02;
  border-radius: 4px;
}

.select-container {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 25px;
}

.select-container::before {
  content: "";
  position: absolute;
  top: 50%;
  height: 25px;
  right: 40px;
  /* Adjust as needed */
  border-right: 1px solid #ABABAB;
  /* Adjust color as needed */
  transform: translateY(-50%);
}

select {
  width: 100%;
  /* Adjust as needed */
  height: 25px;

  border: none;
  background-color: #EEEEEE;
  color: black;
  font-size: 15px;
  display: flex;
  text-align: center;
  border: solid 1px #ABABAB;
  border-radius: 5px;
  -webkit-appearance: none;
  /* Remove default select dropdown arrow in Webkit browsers */
  -moz-appearance: none;
  /* Remove default select dropdown arrow in Firefox */
  appearance: none;
  /* Remove default select dropdown arrow in standard syntax */
}

.select-arrow {
  content: "";
  position: absolute;
  height: 12.5px;
  width: 12.5px;
  top: 35%;
  right: 15px;
  border: solid #ABABAB;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 3px;
  transform: translateY(-50%) rotate(45deg);
  -webkit-transform: translateY(-50%) rotate(45deg);
  pointer-events: none;
}

.suggestion-container {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.suggestion-container span {
  color: black;
}

/* This applies to the entire scrollbar */
.matriz-container::-webkit-scrollbar {
  width: 5px;
  height: 7.5px;
  border-radius: 5px;
  background: #F2D980;
}

/* This applies to the scrollbar track (the background) */
.matriz-container::-webkit-scrollbar-track {
  background: #F2D980;
}

/* This applies to the scrollbar thumb (the moving part) */
.matriz-container::-webkit-scrollbar-thumb {
  background: #F8BC02;
  border-radius: 50px;
}

/* Change the scrollbar thumb color on hover */
.matriz-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Estilos para la tabla */
.table-container {
  width: 100%;
  border-collapse: collapse;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container th, .table-container td {
  padding: 8px;
  border: 1px solid #ddd;
}

.table-container th {
  background-color: #f2f2f2;
  text-align: left;
}

/* Estilos específicos para la primera columna */
.table-container td:first-child, .table-container th:first-child {
  width: 30%;
}

/* Estilos específicos para la segunda columna */
.table-container td:nth-child(2), .table-container th:nth-child(2) {
  width: 70%;
}

</style>