<template>
  <Course />
</template>

<script>
import Course from "@/components/auth/course/Course.vue";
export default {
  components: {
    Course,
  },
};
</script>
