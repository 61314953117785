<template>
  <div
    class="mb-3 px-4 bg-white"
    style="border-radius: 20px; margin-top: 40px; margin-right: 10px"
  >
  <div class="row">
    <div class="col-lg-6 col-md-6">
      <p
        class="text-left"
        style="
          font-size: 1.3em;
          font-weight: 600;
          margin-left: 20px;
          display: inline;
        "
      >
        {{ title }}
      </p>
    </div>

  </div>
    <div class="temario pb-3 overflow-auto">



      <div>
        <div v-if="content == 'temary'">
          <template v-if="course.modules.length !== 0">
            <ul
              v-for="(model, index) in course.modules"
              :key="index"
              class="mt-3"
            >
              <li class="nav-temario" :title="model.name">
                <p class="module-text" b-toggle="model.name.replace(/ /g, '')">
                  {{ index + 1 }}. {{ model.name }}
                </p>
  
                <!-- <b-collapse visible :id="model.name.replace(/ /g, '')"> -->
                  <ul class="p-0">
                    <li
                      v-for="(less, index) in course.modules[index].lessons"
                      :key="index"
                    >
                      <a
                        class="m-0"
                        @click="changeClass(less)"
                        :class="{ activo: less.name === clase}"                      
                        :title="less.name"
                        >
                        <input
                          type="checkbox"
                          :value="less.id"
                          disabled
                          :checked="less.checkpoint"
                        />
                        <div class="ml-7 pl-2" :class="{ selected: less.name === clase }">
                          {{ less.name }}
                        </div>
                      </a>
                    </li>
                  </ul>
                <!-- </b-collapse> -->
              </li>
            </ul>
          </template>
          <template v-else>
            <div class="py-5 px-2 text-danger">
              Este curso aún no tiene clases
            </div>
          </template>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "Temario",
  props: {
    course: Object,
    productType: Number,
  },
  data() {
    return {
      content: "temary",
      title: "Temario",

      items: [
        { title: "Temario", action: "temary" },
        { title: "Exámenes", action: "tests" },
        { title: "Dinámicas", action: "games" },
      ],
      progress: 0,
      clase: null,
      completedLessons: [],
      loading: true,
    };
  },
  methods: {
    menuActionClick(action) {
      if (action === "temary") {
        this.content = "temary";
        this.title = "Temario";
        
      } else if (action === "tests") {
        this.content = "tests";
        this.title = "Exámenes";
      } else if (action === "games") {
        this.content = "games";
        this.title = "Dinámicas";
      }
    },

    //Ir al Examen Modulo
    goToExam(id) {
      this.$router.push({
        name: "test",
        params: { id },
        query: {
          class: this.$route.query.class,
          course: this.$route.query.course,
        },
      });
    },
    goToDinamics(id) {
      this.$router.push({
        name: "dinamic",
        params: { id },
        query: { c: this.$route.query.course },
      });
    },

    // Funcion para calcular el progreso del curso
    // async getProgress() {
    //   const completed = await Object.keys(this.completedLessons).length;
    //   const progress = await Math.round((completed / this.allLessons) * 100);

    //   if (isNaN(progress)) {
    //     this.progress = 0;
    //     this.UPDATE_PROGRESS_COURSE(0);
    //   } else {
    //     this.progress = progress;
    //     this.UPDATE_PROGRESS_COURSE(progress);
    //   }
    // },

    // Cambiar de clase
    changeClass(less) {
    // Cambiando de ruta  
      if(this.$route.params.mode ==  "preview"){
        const config = {
          slug: less.slug,
          productSlug: this.$route.params.productSlug,
          mode: "preview"
        }
        this.$router.push({
          name: "preview",
          params: config,
        })
      }else{
        const config = {
          type: this.getProductType(this.productType),
          slug: less.slug,
      }
        this.$router.push({
          name: "curso",
          params: config,
        });
      }
    },

    getProductType(id) {
      if (id == 1) {
        return "curso";
      } else if (id == 2) {
        return "diplomado";
      }
    },
    // Clases completadas
    getCompletedLessons(id) {
      this.$axios.get(`purchased/show?course_id=${id}`).then((res) => {
        for (const index in res.data.data) {
          if (res.data.status[index] === "SEEN") {
            this.completedLessons.push(res.data.data[index]);
          }
        }
      });
    },

    // Enviando nueva clase vista
    checkClass(idClass) {
      this.$axios.put(
        `purchased/update?course_id=${this.$route.query.course}&class_id=${idClass}`
      );
    },
    getClassInfo(){
      this.$axios.get(`/public/course/class/${this.$route.params.slug}/info`).then((response) => {
        this.clase = response.data.name
      })
    }
  },
  created() {
    // this.getCompletedLessons(this.$route.query.course);
  },
  updated() {
    // Actualizando la barra de progreso
    // this.getProgress();
  },
  watch: {
    "$route.params.slug": {
      immediate: true,
      handler() {
        this.getClassInfo();
      },
    },
  },
  destroyed() {
    this.DESTROY_PROGRESS_COURSE();
  },
};
</script>

<style scoped>
@import "./style.css";
</style>
