<template>
  <div class="main-container">
    <div v-for="(message, index) in chatMessages" :key="index">
      <div class="receptor" v-if="message.from === 'bot'">
        <div class="receptor-logo">
          <img src="@/assets/img/Ingenierito.png" alt="logo-receptor" />
          <span class="receptor-time">{{ message.time }}</span>
        </div>
        <div class="receptor-mensaje">
          <p>{{ message.text }}
            <button class="copy-button" @click="copyBotMessage(message.text)">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M19.53 8L14 2.47C13.8595 2.32931 13.6688 2.25018 13.47 2.25H11C10.2707 2.25 9.57118 2.53973 9.05546 3.05546C8.53973 3.57118 8.25 4.27065 8.25 5V6.25H7C6.27065 6.25 5.57118 6.53973 5.05546 7.05546C4.53973 7.57118 4.25 8.27065 4.25 9V19C4.25 19.7293 4.53973 20.4288 5.05546 20.9445C5.57118 21.4603 6.27065 21.75 7 21.75H14C14.7293 21.75 15.4288 21.4603 15.9445 20.9445C16.4603 20.4288 16.75 19.7293 16.75 19V17.75H17C17.7293 17.75 18.4288 17.4603 18.9445 16.9445C19.4603 16.4288 19.75 15.7293 19.75 15V8.5C19.7421 8.3116 19.6636 8.13309 19.53 8ZM14.25 4.81L17.19 7.75H14.25V4.81ZM15.25 19C15.25 19.3315 15.1183 19.6495 14.8839 19.8839C14.6495 20.1183 14.3315 20.25 14 20.25H7C6.66848 20.25 6.35054 20.1183 6.11612 19.8839C5.8817 19.6495 5.75 19.3315 5.75 19V9C5.75 8.66848 5.8817 8.35054 6.11612 8.11612C6.35054 7.8817 6.66848 7.75 7 7.75H8.25V15C8.25 15.7293 8.53973 16.4288 9.05546 16.9445C9.57118 17.4603 10.2707 17.75 11 17.75H15.25V19ZM17 16.25H11C10.6685 16.25 10.3505 16.1183 10.1161 15.8839C9.8817 15.6495 9.75 15.3315 9.75 15V5C9.75 4.66848 9.8817 4.35054 10.1161 4.11612C10.3505 3.8817 10.6685 3.75 11 3.75H12.75V8.5C12.7526 8.69811 12.8324 8.88737 12.9725 9.02747C13.1126 9.16756 13.3019 9.24741 13.5 9.25H18.25V15C18.25 15.3315 18.1183 15.6495 17.8839 15.8839C17.6495 16.1183 17.3315 16.25 17 16.25Z" fill="#C3C3C3"/>
              </svg>
            </button></p>
        </div>
      </div>
      <div class="emisor" v-else>
        <span class="emisor-time">{{ message.time }}</span>
        <div class="emisor-mensaje">
          <p>{{ message.text }}</p>
        </div>
      </div>
    </div>
    <button class="boton-responder" v-if="loading" type="button">
      <span class="boton-text">Dejar de responder</span>
      <div class="boton-icon">
        <div class="boton-icon_1"></div>
        <div class="boton-icon_2"></div>
      </div>
    </button>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "DefaultChat",
  data() {
    return {
      message: "Default",
      chatMessages: [],
    };
  },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  methods:{
    copyBotMessage(text) {
      // Crea un elemento de texto oculto
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);

      // Selecciona y copia el texto
      textArea.select();
      document.execCommand('copy');

      // Elimina el elemento de texto oculto
      document.body.removeChild(textArea);
    },
  },
  watch: {
    messages(newMessages) {
      this.chatMessages = newMessages;
    },
  },
});
</script>

<style scoped>
/* container */

.main-container {
  display: flex;
  color: #000;
  position: absolute;
  flex-direction: column;
  right: 5px;
  height: 460px;
  width: 390px;
  overflow-y: auto;
  overflow-x: hidden;
}
.emisor-time {
  position: relative;
  font-family: Montserrat;
  font-size: 10px;
  right: -80px;
}
.emisor-triangulo {
  position: relative;
  width: 0;
  height: 0;
  border-right: 28px solid transparent;
  border-bottom: -12px solid rgba(238, 238, 238, 1);
  border-top: 22px solid rgba(238, 238, 238, 1);
  top: 24px;
}
.emisor-mensaje {
  background: rgb(238, 238, 238);
}
.emisor-mensaje, .receptor-mensaje {
  top: 20px;
  height: auto;
  max-width: 310px; /* Ancho máximo para los mensajes */
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 5px; /* Espacio entre mensajes */
}
.emisor-mensaje p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  padding: 0px 23px;
}
.receptor, .emisor {
  position: relative; 
  width: auto;
}
.receptor {
  left: 20px;
}
.emisor{
  left: 68px;
}
.receptor-logo {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: relative;
  overflow: hidden;
  width: 150px;
  height: 46px;
}
.receptor-logo img {
  width: 44px;
  height: 44px;
}
.receptor-time {
  position: relative;
  font-family: Montserrat;
  font-size: 10px;
  left: 5px;
}
.receptor-mensaje {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  background: #FFF59D;
  opacity: 1;
  overflow: hidden;
}
.receptor-mensaje p {
  padding: 0px 20px;
}
.receptor-mensaje ol {
  padding: 0px 20px 0px 32px;
}
.boton-responder {
  width: 216.77px;
  height: 29.9px;
  border-radius: 10px;
  border: 1px solid #f8bc02;
  background: #ffffff;
  position: absolute;
  bottom: 3%;
  left: 25%;
  cursor: pointer;
}
.boton-text {
  width: 115px;
  color: rgba(248, 188, 2, 1);
  position: absolute;
  top: 6px;
  left: 64px;
  font-family: Anek Latin;
  font-size: 12px;
  opacity: 1;
  text-align: center;
}
.boton-icon {
  width: 19px;
  height: 19px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 5px;
  left: 38px;
  overflow: hidden;
}
.boton-icon_1 {
  width: 19px;
  height: 19px;
  background: rgba(248, 188, 2, 1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
}
.boton-icon_2 {
  width: 8px;
  height: 8px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: absolute;
  top: 5px;
  left: 6px;
  overflow: hidden;
}
.copy-button {
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-block; 
  margin-top: -100px;
  margin-left: 248px; /* Ajusta el espacio entre el mensaje y el botón */
}

.copy-button svg {
  width: 20px; /* Ajusta el tamaño del icono */
  height: 20px;
  fill: #C3C3C3; /* Ajusta el color del icono según tus preferencias */
}

/* Ajusta la posición y el espacio del icono dentro del botón */
.copy-button span {
  margin-left: 5px;
}
</style>
