<template> 
  <form>
    <label for="task">Tarea</label>
    <input type="text" placeholder="Descripción ..." v-model="data.newTask" id="task" required />
    <div class="lugar-posicion">
    <div class="lugar">
        <label for="place">Lugar</label>
        <select v-model="data.selectedLugar" class="form-control" id="place" @change="handleLugar">
          <option value="0" disabled selected>Selecciona un lugar</option>
          <option v-for="place in lugares" :key="place.id" :value="place.id">{{ place.name }}</option>
          <option value="añadir">Añadir Lugar</option>
        </select>
    </div>
    <div>
        <label for="workPosition">Posición de trabajo</label>
        <select v-model="data.selectedPosicion" class="form-control" id="workPosition" @change="handleTrabajo">
          <option value="0" disabled selected>Selecciona un trabajo</option>
          <option v-for="trabajo in trabajos" :key="trabajo.id" :value="trabajo.id">{{ trabajo.name }}</option>
          <option value="añadir">Añadir Trabajo</option>
        </select>
    </div>
    </div>
    <label for="danger">Peligro</label>
    <input
    type="text"
    placeholder="Describe el peligro aquí ..."
    v-model="data.newDanger"
    id="danger"
    required
    />
    <label for="risk">Riesgo</label>
    <input
    type="text"
    placeholder="Describe el riesgo aquí ..."
    v-model="data.newRisk"
    id="risk"
    required
    />
    <label for="consequence">Consecuencia</label>
    <input
    type="text"
    placeholder="Describe la consecuencia aquí ..."
    v-model="data.newConsequence"
    id="consequence"
    required
    />
    <label for="type">Tipo de peligro: </label>
    <select class="tipo" id="type" v-model="data.selectedType">
    <option value="" disabled selected>Selecciona un tipo de peligro</option>
    <option value="Químico">Químico</option>
    <option value="Físico">Físico</option>
    <option value="Biológico">Biológico</option>
    <option value="Ergonómico">Ergonómico</option>
    <option value="Psicosocial">Psicosocial</option>
    <!-- Opciones del tipo aquí -->
    </select>
    <div class="enviar">
      <label for="routine"><input type="checkbox" v-model="data.routine" id="routine" />¿Es rutinario?</label>
      <button type="button" :disabled="!isFormFilled" @click="submitIPERC">ENVIAR</button>
    </div>
  
    <ContainedModal
      :isOpen="showLugarModal"
      @updateModal="showLugarModal = $event"
      :styleObject="{ width: '306px', height: '182px' }"
      >
      <div class="modal-content2">
        <h2>AÑADE UN LUGAR</h2>
        <input type="text" v-model="newLugar" placeholder="NOMBRE (LUGAR)" />
        <button @click="confirmNewLugar" class="close">Listo</button>
      </div>
    </ContainedModal>
    <ContainedModal
      :isOpen="showTrabajoModal"
      @updateModal="showTrabajoModal = $event"
      :styleObject="{ width: '306px', height: '182px' }"
      >
      <div class="modal-content2">
        <h2>AÑADE UN TRABAJO</h2>
        <input type="text" v-model="newPosition" placeholder="NOMBRE (TRABAJO)" />
        <button @click="confirmNewTrabajo" class="close">Listo</button>
      </div>
    </ContainedModal>
    
  </form>
</template>
<script>
import ContainedModal from "./ContainedModal.vue";
export default {
    name: "ActivityForm",
    props: {
      cleanAll: Boolean,
    },
    components: {
        ContainedModal
    },
    emits: ['activity'],
    data() {
        return {
            lugares: [],
            trabajos: [],
            showLugarModal: false,
            showTrabajoModal: false,
            newLugar: '',
            newPosition: '',
            places_id: 0,
            work_positions_id: 0,
            showEval: false,
            showControl: false,
            previousView: '',
            risks_id: 0,
            tasks_id: 0,
            dangers_id: 0,
            consequences_id: 0,
            iperc_id: 0,
            data: {
                newTask: '',
                newDanger: '',
                newRisk: '',
                newConsequence: '',
                selectedType: '',
                selectedLugar: 0,
                selectedPosicion: 0,
                routine: false,
            }
        };
    },
    mounted(){
        this.fetchPlaces();
        this.fetchWorkPositions();
    },
    methods: {
        clearInputsData() {
          // Limpia los datos de los inputs
          this.newLugar = '';
          this.newPosition = '';
          this.places_id = 0;
          this.work_positions_id = 0;
          this.showEval = false;
          this.showControl = false;
          this.previousView = '';
          this.risks_id = 0;
          this.tasks_id = 0;
          this.dangers_id = 0;
          this.consequences_id = 0;
          this.iperc_id = 0;
          this.data = {
              newTask: '',
              newDanger: '',
              newRisk: '',
              newConsequence: '',
              selectedType: '',
              selectedLugar: 0,
              selectedPosicion: 0,
              routine: false,
          };
        },
        submitIPERC() {
            this.$emit('activity',this.data)
        },
        handleLugar() {
            if (this.data.selectedLugar === "añadir") {
                this.showLugarModal = true;
            }
        },
        handleTrabajo() {
            if (this.data.selectedPosicion === "añadir") {
                this.showTrabajoModal = true;
            }
        },
        fetchPlaces() {
          this.$axios.get(`/chatbot/place`)
            .then(response => {
                this.lugares = response.data;
            })
            .catch(error => {
                console.error('Error fetching places:', error);
            });
        },
        fetchWorkPositions(){
          this.$axios.get(`/chatbot/work-position`)
            .then(response => {
                this.trabajos = response.data;
            })
            .catch(error => {
                console.error('Error fetching work positions:', error);
            });
        },
        confirmNewLugar() {
        const valLugar = this.newLugar.trim();
        if (valLugar !== "") {
            this.createPlace(valLugar);
            this.showLugarModal = false;
        } else {
            alert("Por favor ingrese un nombre para el lugar.");
        }
        },
        createPlace(valLugar){
          this.$axios.post(`/chatbot/place`, {
            name: valLugar
          })
            .then(response => {
              this.places_id = response.data.place.id;
              console.log(response.data.message);
            })
            .catch(error => {
              console.error('Error al crear el lugar:', error);
            });
        },
        confirmNewTrabajo() {
        const valTrabajo = this.newPosition.trim();
        if (valTrabajo !== "") {
            this.createWorkPosition(valTrabajo);
            this.showTrabajoModal = false;
        } else {
            alert("Por favor ingrese un nombre para el trabajo.");
        }
        },
        createWorkPosition(valTrabajo) {    
          this.$axios.post(`/chatbot/work-position`, {
            name: valTrabajo,
          })
            .then(response => {
              this.work_positions_id = response.data.workPosition.id;
              console.log(response.data.message);
            })
            .catch(error => {
              console.error('Error al crear la posición de trabajo:', error);
              
            });
        },
        
    },
    computed :{
        isFormFilled() {
            return this.data.newTask !== '' && this.data.newDanger !== '' && this.data.newRisk !== '' && this.data.newConsequence !== '' && this.data.selectedType !== '' && this.data.selectedLugar !== 0 && this.data.selectedPosicion !== 0;
        },
    },
    watch:{
      cleanAll(value) {
        if (value) {
          this.clearInputsData();
        }
      },
    }
    
};
</script>

<style scoped>
/* modal de nombre de proceso */
.modal-content2 {
  background: #f8bc02;
  width: 306px;
  height: 182px;
  text-align: center;
  border-radius: 20px;
  display: flex;
  border: 3px solid white;
  padding: 13px 0px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: space-between;
}

.modal-content2 h2 {
  font-size: 15px !important;
  font-family: "Anek Latin", sans-serif !important;
  font-weight: 500 !important;
  color: white !important;
  width: 140px !important;
  height: auto !important;
  margin-top: 11px !important;
  text-align: center !important;
}

.modal-content2 input {
  border: 1px solid #b9b9b9 !important;
  width: 254px !important;
  height: 40px !important;
  border-radius: 10px !important;
  margin-top: 12px !important;
}

.modal-content2 input::placeholder {
  font-family: "Anek Latin", sans-serif !important;
  text-align: center !important;
  font-size: 20px !important;
  color: black !important;
  font-weight: 700 !important;
}

.modal-content2 input:focus {
  outline: none !important;
}

.modal-content2 button {
  border: 2px solid white !important;
  background: #f8bc02 !important;
  margin-top: 21px !important;
  width: 93px !important;
  height: 30px !important;
  border-radius: 10px !important;
  color: white !important;
  font-family: "Anek Latin", sans-serif !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  cursor: pointer!important;
  margin-top: -40px;
}

/* registro de editar */
.nuevo-contenedor {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Anek Latin", sans-serif;
  font-size: 15px;
  margin-top: -20px;
}

.nuevo-contenedor .btn-regresar {
  width: 338px;
  display: flex;
  font-family: "Anek Latin", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #f8bc02;
  background: white;
  border: none;
  justify-content: flex-start;
  margin: 12px 0px;
  cursor: pointer;
}

.nuevo-contenedor .btn-regresar img {
  margin-right: 8px;
}

.nuevo-contenido {
  font-family: "Anek Latin", sans-serif !important;
  font-size: 15px !important;
}

.nuevo-contenido button{
  width: 161px;
  height: 48px;
  font-family: "Anek Latin", sans-serif;
  font-size: 15px;
  color: white;
  background: #f8bc02;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  padding: 0px 20px;
  margin-bottom: 10px;
  margin-right: 4px;
  cursor: pointer;
}

.nuevo-contenido button:disabled {
  opacity: 0.5;
}

.nuevo-contenido form {
  display: flex;
  flex-direction: column;
  width: 332px;
  line-height: 22px;
  text-align: left;
}

.nuevo-contenido form input {
  width: 332px;
  height: 25px;
  background: #eeeeee;
  border: 1px solid #ababab;
  border-radius: 4px;
  margin: 0;
}

.nuevo-contenido input::placeholder {
  font-family: "Anek Latin", sans-serif;
  font-weight: 300;
  font-size: 15px;
  text-align: left;
  color: #656565;
  padding: 0px 10px;
}

.nuevo-contenido form input:focus {
  outline: none;
}

.nuevo-contenido form label {
  font-weight: 600;
  margin-bottom: 0;

}

.nuevo-contenido .lugar-posicion {
  display: flex;
}

.nuevo-contenido .lugar-posicion .lugar {
  margin-right: 10px;
}

.nuevo-contenido .lugar-posicion select {
  width: 161px;
  height: 25px;
  background: #eeeeee;
  border: 1px solid #ababab;
  border-radius: 4px;
  padding: 0;
  font-size:15px;
}

.nuevo-contenido select:focus {
  outline: none;
}

.nuevo-contenido .lugar-posicion input {
  width: 161px;
  height: 25px;
}

.nuevo-contenido .tipo {
  width: 332px;
  height: 25px;
  background: #eeeeee;
  border: 1px solid #ababab;
  border-radius: 4px;
  font-size:15px;
}

.nuevo-contenido form .enviar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 9px;
}

.nuevo-contenido .enviar button {
  height: 40px;
  width: 121px;
}

.nuevo-contenido .enviar button:disabled {
  opacity: 0.5;
}


.nuevo-contenido .enviar label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.nuevo-contenido .enviar input {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

/* formulario riesgo */
.formulario-riesgo h3 {
  font-family: "Anek Latin", sans-serif;
  font-weight: 800;
  font-size: 15px;
  margin: 0px;
}

.formulario-riesgo label {
  font-family: "Anek Latin", sans-serif;
  font-weight: 600;
  font-size: 15px;
}

.formulario-riesgo select {
  width: 332px;
  height: 25px;
  background: #eeeeee;
  border: 1px solid #ababab;
  border-radius: 4px;
  margin-bottom: 10px;
}

.formulario-riesgo .chekbox {
  display: flex;
  justify-content: center;
}

.formulario-riesgo .chekbox input {
  width: 14px;
  height: 18px;
}

.formulario-riesgo .botones {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formulario-riesgo .botones button {
  width: 121px;
  height: 40px;
}

.formulario-riesgo .botones button:nth-child(1) {
  background: white;
  color: black;
  display: flex;
  padding: 5px;
  border: 3px solid #f8bc02;
  align-items: center;
}

.formulario-riesgo .botones button:nth-child(2) {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.formulario-riesgo .botones button:nth-child(2).disabled {
  opacity: 0.5;
}
input::after{
  content: none;
}
label[for="routine"]{
  display: flex;
  column-gap: 10px;
}
input[type="checkbox"]{
  position: relative;
}
</style>