<template>
    <div class="ipssobot-modal" v-if="isOpen">
      <div class="main-options-chat">
        <button class="option option-matriz-iperc">Matriz IPERC</button>
        <button class="option option-chat-bot selected">ChatBot</button>
        <!--button class="option">Más preciso</button-->
      </div>
      <div class="main1">
        <div class="main-logo">
          <div class="logo">
            <img src="@/assets/img/Ingenierito.png" alt="logo" />
          </div>
          <div class="title">
            <p>¡Hola! Soy</p>
            <h1>IPSSOBOT</h1>
            <p>¿Cómo te puedo ayudar?</p>
          </div>
        </div>
      </div>
  
      <div class="main-container chat-bot">
        <div v-for="(message, index) in chatMessages" :key="index">
          <div class="receptor-chat" v-if="message.from ==='bot'">
            <div class="receptor-chat-logo">
              <img src="@/assets/img/Ingenierito.png" alt="logo-receptor-chat" />
              <span class="receptor-chat-time">{{ message.time }}</span>
            </div>
            <div class="receptor-chat-mensaje">
              <p>{{ message.text }}
                <button class="copy-button" @click="copyBotMessage(message.text)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M19.53 8L14 2.47C13.8595 2.32931 13.6688 2.25018 13.47 2.25H11C10.2707 2.25 9.57118 2.53973 9.05546 3.05546C8.53973 3.57118 8.25 4.27065 8.25 5V6.25H7C6.27065 6.25 5.57118 6.53973 5.05546 7.05546C4.53973 7.57118 4.25 8.27065 4.25 9V19C4.25 19.7293 4.53973 20.4288 5.05546 20.9445C5.57118 21.4603 6.27065 21.75 7 21.75H14C14.7293 21.75 15.4288 21.4603 15.9445 20.9445C16.4603 20.4288 16.75 19.7293 16.75 19V17.75H17C17.7293 17.75 18.4288 17.4603 18.9445 16.9445C19.4603 16.4288 19.75 15.7293 19.75 15V8.5C19.7421 8.3116 19.6636 8.13309 19.53 8ZM14.25 4.81L17.19 7.75H14.25V4.81ZM15.25 19C15.25 19.3315 15.1183 19.6495 14.8839 19.8839C14.6495 20.1183 14.3315 20.25 14 20.25H7C6.66848 20.25 6.35054 20.1183 6.11612 19.8839C5.8817 19.6495 5.75 19.3315 5.75 19V9C5.75 8.66848 5.8817 8.35054 6.11612 8.11612C6.35054 7.8817 6.66848 7.75 7 7.75H8.25V15C8.25 15.7293 8.53973 16.4288 9.05546 16.9445C9.57118 17.4603 10.2707 17.75 11 17.75H15.25V19ZM17 16.25H11C10.6685 16.25 10.3505 16.1183 10.1161 15.8839C9.8817 15.6495 9.75 15.3315 9.75 15V5C9.75 4.66848 9.8817 4.35054 10.1161 4.11612C10.3505 3.8817 10.6685 3.75 11 3.75H12.75V8.5C12.7526 8.69811 12.8324 8.88737 12.9725 9.02747C13.1126 9.16756 13.3019 9.24741 13.5 9.25H18.25V15C18.25 15.3315 18.1183 15.6495 17.8839 15.8839C17.6495 16.1183 17.3315 16.25 17 16.25Z" fill="#C3C3C3"/>
                  </svg>
                </button></p>
            </div>
          </div>
          <div class="emisor-chat" v-else>
            <span class="emisor-chat-time">{{ message.time }}</span>
            <div class="emisor-chat-mensaje">
              <p>{{ message.text }}</p>
            </div>
          </div>
        </div>
        <button class="boton-responder" v-if="loading" type="button">
          <span class="boton-text">Dejar de responder</span>
          <div class="boton-icon">
            <div class="boton-icon_1"></div>
            <div class="boton-icon_2"></div>
          </div>
        </button>
      </div>
  
      <div class="footer-chat">
        <div class="mensaje">
          <form @submit.prevent="handleSubmit">
            <input type="text" placeholder="Preguntame cualquier cosa..." v-model="prompt"/>
            <input type="hidden" v-model="selectedConversationId"/>
            <button class="send-button" type="submit"><img class="imagen" src="@/assets/img/send.png" alt="send" /></button>
            
          </form>
        </div>
        <div class="options">
          <div class="img file-icon" @click="toggleIconContainer">
            <img src="@/assets/img/attach_file.png" alt="file" />
          </div>
          <div
            class="icon-container"
            :style="{ display: showIconContainer ? 'flex' : 'none' }"
          >
            <div class="img-icon">
              <img src="@/assets/img/imagesmode.png" alt="image" />
            </div>
            <div class="img-icon">
              <img src="@/assets/img/location_on.png" alt="location" />
            </div>
            <div class="img-icon">
              <img src="@/assets/img/description.png" alt="document" />
            </div>
            <div class="img-icon">
              <img src="@/assets/img/account_box.png" alt="contacts" />
            </div>
            <div class="rombo"></div>
          </div>
          <div class="img">
            <img src="@/assets/img/photo_camera.png" alt="camera" />
          </div>
          <div class="img">
            <img src="@/assets/img/mic.png" alt="microfono" />
          </div>
        </div>
        <button @click="closeModal" class="btn-volver1">
          Volver <img src="@/assets/img/flecha.png" alt="flecha" />
        </button>
      </div>
      <!-- Botón para cerrar la vista modal -->
    </div>
  </template>
  
  <script>
  import moment from "moment";
  export default {
    props:{
      isOpen:Boolean,
      chatGptPrompt: String,
    },
    emits:['closeModal'],
    data() {
      return {
        loading: false,
        selectedConversationId: null,
        showIconContainer: false,
        prompt: "",
        chatMessages: [],
        chatConversations: [],
      };
    },
    created(){
      this.startNewChat();
    },
    watch: {
      chatGptPrompt: {
        immediate: true,
        handler(newVal) {
          this.prompt = newVal;
        }
      }
    },
    methods: {
      closeModal() {
        // Emitir el evento 'closeModal' cuando se hace clic en el botón "Volver"
        this.$emit("closeModal",false);
      },
      toggleIconContainer() {
        this.showIconContainer = !this.showIconContainer;
      },
      copyBotMessage(text) {
        // Crea un elemento de texto oculto
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);

        // Selecciona y copia el texto
        textArea.select();
        document.execCommand('copy');

        // Elimina el elemento de texto oculto
        document.body.removeChild(textArea);
      },
      startNewChat() {
        // Hacer una llamada a la API para crear una nueva conversación
        this.$axios.post(`/chatbot/createConversation`)
          .then((response) => {
            // Obtener el ID de la nueva conversación
            const newChatId = response.data.id;

            // Inicializar chatMessages como un array vacío
            const newChatMessages = [];

            // Agrega una nueva conversación
            this.chatConversations.push({
              id: newChatId,
              messages: newChatMessages,
            });

            // Selecciona automáticamente la nueva conversación
            this.selectedConversationId = newChatId;

            // Agrega el mensaje de default
            this.chatMessages = [{ from: 'bot', // El mensaje es del bot
              text: '¡Hola! Soy Ipssobot, te ayudaré en todas las dudas que tengas sobre los cursos, diplomas, exámenes, y más. Dime, ¿Cuál es tu consulta?', time: moment().format('hh:mm A')}];
          })
          .catch((error) => {
            console.error(error);
          });
      },
      async handleSubmit() {
      
        // Agrega el mensaje del usuario al chat
        const userMessage = { text: this.prompt, from: 'user', time: moment().format('hh:mm A') };
        this.loading = true;
        this.chatMessages.push(userMessage);
        try {
          const response = await this.$axios.post(`/chatbot/chat`, {
            queryResult: this.prompt,
            conversationId: this.selectedConversationId
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*' // Asegúrate de configurarlo de manera segura en producción
            }
          });

            this.chatMessages.push({ text: response.data.response, from: 'bot', time: moment().format('hh:mm A') });
        }
        catch (err) {
          console.error('Error:', err);
        }
        finally{
          this.loading = false;
        }
        this.prompt='';
      },
    },
  };
  </script>
  
  <style scoped>
  /* Estilos CSS para el componente modal */
  .ipssobot-modal {
    position: fixed;
    top:144px;
    right:400px;
    width: 817px;
    height: 606px;
    background: white;
    border: 3px solid #f8bc02;
    border-radius: 25px;
    z-index: 99999999 !important;
    background: #ffffff;
  }

  .main-container {
    height: 380px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  /* Estilos para el thumb (manija) de la barra de desplazamiento en Firefox */
  .main-logo {
    font-family: "Anek Latin", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .main-logo .logo img {
    width: 69.87px;
    height: 69.87px;
    margin-right: 10px;
  }
  .main-logo .title {
    line-height: 4px;
    text-align: justify;
    color: black;
    font-weight: 700;
  }
  .main-logo .title p {
    font-size: 12px;
  }
  .main-logo .title h1 {
    font-size: 25px;
  }
  /* opciones de chatbot */
  .main-options-chat {
    display: flex;
    /* margin: 10px; */
    margin-bottom: 20px;
    font-weight: bold;
    /* margin-top: 20px; */
    align-items: center;
    justify-content: space-around;
  }
  .main-options-chat .option {
    font-family: "Anek Latin", sans-serif;
    font-size: 25px;
    align-items: center;
    background: #b4b4b4;
    width: 100%;
    height: 53px;
    /* padding: 10px 50px; */
    text-align: center;
    /* margin: 2px; */
    /* border-radius: 10px; */
    border: none;
    display: flex;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s;
    margin: 0px;
    padding: 0px;
    color: white;
  }
  .main-options-chat .option:nth-child(1) {
    border-radius: 20px 0px 0px 0px;
  }
  .main-options-chat .option:nth-child(3) {
    border-radius: 0px 20px 0px 0px;
  }
  .option.selected {
    background: #f8bc02;
    color: white;
    font-weight: 600;
  }
  /* Cambia el color cuando el botón está seleccionado */
  
  .main-options-chat .option:nth-child(2) {
    height: 54px;
  }
  .footer-chat {
    font-family: "Anek Latin", sans-serif;
    background: #f8bc02;
    display: flex;
    justify-content: space-between;
    /*  padding: 0px 20px; */
    align-items: center;
    border-radius: 0px 0px 15px 15px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 55.13px;
  }
  .footer-chat .options {
    display: flex;
    cursor: pointer;
    margin: 0px 117px 0px -7px;
  }
  .footer-chat .options div {
    margin-left: 20px;
  }
  .footer-chat .options .img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27px;
    height: 27px;
  }
  .footer-chat .options div {
    margin-left: 20px;
  }
  .footer-chat .options .img:hover {
    background: #bf9500;
    border-radius: 50%;
  }
  .footer-chat .options img {
    width: 24.66px;
    height: 24.66px;
  }
  
  .footer-chat .mensaje {
    display: flex;
    align-items: center;
    position: relative;
    width: 496px;
    margin: 0 6px;
  }
  .footer-chat .mensaje input {
    width: 496px;
    height: 41px;
    padding: 0px 14px;
    border: 1px solid #ccc;
    border-radius: 15px;
    padding-right: 40px;
    background-color: white;
  }
  .footer-chat input:focus {
    outline: none;
  }
  .footer-chat .mensaje ::placeholder {
    font-family: "Anek Latin", sans-serif;
    font-weight: 300;
    font-size: 15px;
    text-align: left;
    color: #b9b9b9;
  }
  .footer-chat .mensaje img {
    position: absolute;
    cursor: pointer;
    left: 91%;
  }
  .footer-chat .imagen {
    position: absolute;
    top: 50%;
    right: 10px; /* Posicionamiento a la derecha */
    transform: translateY(-50%);
    width: 20px; /* Ancho de la imagen */
    height: auto;
  }
  /* codigo nuevo */
  /* Estilos para los iconos de opciones */
  .footer-chat .icon-container {
    position: absolute;
    bottom: 58px;
    background: rgb(191, 149, 0);
    height: 32.67px;
    width: 115px;
    right: 32px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-chat .icon-container img {
    width: 22.83px;
    height: 22.83px;
  }
  .emisor-chat{
    bottom: 10px;
  }
  .footer-chat .btn-volver1 {
    display: flex;
    width: 129px;
    height: 38px;
    position: relative;
    left: -82px;
    padding: 0px 34px;
    border-radius: 10px;
    border: 1px solid white;
    color: #f8bc02;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    cursor: pointer;
    background-color: #b4b4b4;
  }
  .footer-chat .btn-volver1 img {
    margin-right: 4px;
  }
  
  /* CONTENIDO */
  .emisor-chat-time{
    width: 52px;
    color: rgba(183, 183, 183, 1);
    position: relative;
    right: -80px;
    font-family: Montserrat;
    font-weight: Regular;
    font-size: 10px;
    opacity: 1;
    text-align: right;
    left: 340px;
  }
  .emisor-chat-mensaje{
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 500;
    width: 504px;
    height: auto;
    background: rgb(238, 238, 238);
    opacity: 1;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    right: -290px;
  }

  .emisor-mensaje p {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    padding: 0px 23px;
  }
  .receptor-chat-logo {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: relative;
    width: 150px;
    height: 46px;
    overflow: hidden;
  }
  .receptor-chat-logo img {
    width: 44px;
    height: 44px;
  }
  .receptor-chat-time {
    width: 52px;
    color: rgba(183, 183, 183, 1);
    position: relative;
    left: 5px;
    font-family: Montserrat;
    font-weight: Regular;
    font-size: 10px;
    opacity: 1;
    text-align: left;
  }
  .receptor-chat-mensaje {
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 500;
    width: 504px;
    height: auto;
    background: #FFF59D;
    opacity: 1;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    left: 15px;
  }
  .receptor-chat-mensaje p {
    padding: 0px 20px;
  }
  .boton-responder {
    width: 169px;
    height: 38px;
    border-radius: 10px;
    border: 2px solid #f8bc02;
    background: #ffffff;
    position: absolute;
    top: 83%;
    left: 39.5%;
    cursor: pointer;
  }
  .boton-text {
    color: rgba(248, 188, 2, 1);
    position: absolute;
    top: 10px;
    left: 36px;
    font-family: Anek Latin;
    font-size: 15px;
    opacity: 1;
    text-align: center;
    font-weight: 600;
  }
  .boton-icon {
    width: 19px;
    height: 19px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 9px;
    left: 12px;
    overflow: hidden;
  }
  .boton-icon_1 {
    width: 19px;
    height: 19px;
    background: rgba(248, 188, 2, 1);
    opacity: 1;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
  }
  .boton-icon_2 {
    width: 8px;
    height: 8px;
    background: rgba(255, 255, 255, 1);
    opacity: 1;
    position: absolute;
    top: 5px;
    left: 6px;
    overflow: hidden;
  }
  .copy-button {
    background: transparent;
    border: none;
    cursor: pointer;
    display: inline-block; 
    margin-top: -10px;
    margin-left: 315px; /* Ajusta el espacio entre el mensaje y el botón */
  }

  .copy-button svg {
    width: 20px; /* Ajusta el tamaño del icono */
    height: 20px;
    fill: #C3C3C3; /* Ajusta el color del icono según tus preferencias */
  }

  /* Ajusta la posición y el espacio del icono dentro del botón */
  .copy-button span {
    margin-left: 5px;
  }
  .send-button {
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
  }
  </style>
  