<template>
    <div class="modal-backdrop1" v-if="isOpen" @click.self="close">
      <div @click.stop :style="styleObject">
        <slot></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ContainedModal",
    props: {
      isOpen: Boolean,
      styleObject: Object,
    },
  
    emits: ["updateModal"],
    methods: {
      close() {
        this.$emit("updateModal", false);
      },
    },
  };
  </script>
  <style scoped>
  .modal-backdrop1 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
  }
  </style>
  