<template>
  <div class="container">
    <div v-if="!chatVisible" class="chat-bubble" @click="handleButtonClick">
      <button class="image-button">
        <img class="image" src="@/assets/img/Ingenierito.png" alt="Descripción de la imagen" />
      </button>
      <svg xmlns="http://www.w3.org/2000/svg" width="95" height="95" viewBox="0 0 95 95" fill="none">
      </svg>
    </div>
    <div v-if="!chatVisible" class="help-bubble">
      <!-- Mensaje de ayuda en forma de burbuja de chat -->
      <p>Hola,¿Necesitas ayuda?</p>
    </div>
    <div class="chat-container" v-show="chatVisible">
      <div class="header">
        <div class="header-icons">
          <div class="header-icons_title">CHAT</div>
          <div class="header-icons_img">
            <div class="icon">
              <img src="@/assets/img/expandir.png" alt="expandir" />
            </div>
            <div class="icon">
              <img src="@/assets/img/pantalla.png" alt="pantalla" />
            </div>
            <div class="icon icon-historial" @click="toggleContext">
              <img src="@/assets/img/historial.png" alt="historial" />
            </div>
            <div v-if="contextVisible" class="chat-context">
              <button class="context-button" @click="startNewChat">Nuevo chat</button>
              <button class="context-button" @click="toggleHistory">Historial</button>
            </div>
            <!-- Contenido del historial-->
            <div class="history-panel" :class="{ 'expanded': historyExpanded }">
              <button class="close-history" @click="toggleHistory">
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7.34923 5.11675C7.58272 5.35112 7.71387 5.66883 7.71387 6.00008C7.71387 6.33133 7.58272 6.64904 7.34923 6.88342L2.64792 11.5984C2.41403 11.8328 2.09684 11.9644 1.76613 11.9644C1.60239 11.9643 1.44025 11.9319 1.28898 11.8691C1.13771 11.8062 1.00028 11.7141 0.884517 11.598C0.768757 11.4819 0.676942 11.3441 0.614314 11.1924C0.551686 11.0407 0.519472 10.8781 0.51951 10.714C0.519549 10.5498 0.55184 10.3872 0.614539 10.2356C0.677238 10.0839 0.769118 9.94614 0.884932 9.83008L4.70516 6.00008L0.884932 2.17008C0.765796 2.05483 0.670748 1.91694 0.605333 1.76447C0.539918 1.612 0.505447 1.44799 0.503931 1.28201C0.502415 1.11604 0.533884 0.951428 0.596502 0.797779C0.659121 0.64413 0.751635 0.504522 0.868645 0.387101C0.985656 0.269681 1.12482 0.176799 1.27802 0.113875C1.43122 0.050952 1.59538 0.0192471 1.76093 0.0206108C1.92648 0.0219746 2.0901 0.0563784 2.24225 0.121817C2.3944 0.187255 2.53202 0.282416 2.64709 0.401749L7.3509 5.11675H7.34923Z"
                    fill="#F8BC02" />
                </svg>
                <h2>Historial</h2>
              </button>
              <div class="chat-conversations" v-if="chatConversations !== null">
                <div v-for="(conversation, index) in chatConversations" :key="index"
                  @click="loadConversationToChat(conversation)">
                  <p>Conversation {{ conversation.conversationId }}</p>
                </div>
              </div>
              <div v-else>
                <p>No hay ninguna conversación por mostrar</p>
              </div>
            </div>
            <div class="icon icon-cerrar" @click="toggleChat">
              <img src="@/assets/img/close.png" alt="cerrar" />
            </div>
          </div>
        </div>
      </div>
      <div class="main">
        <div class="main-logo">
          <div class="logo">
            <img src="@/assets/img/Ingenierito.png" alt="logo" />
          </div>
          <div class="title">
            <p>¡Hola! Soy</p>
            <h1>IPSSOBOT</h1>
            <p>¿Cómo te puedo ayudar?</p>
          </div>
        </div>

        <div class="main-options">
          <button class="option option-matriz-iperc" @click="changeComponent('MatrizIperc')"
            :class="{ 'selected': currentComponent === 'MatrizIperc' }">
            Matriz IPERC
          </button>
          <button class="option option-chat-bot" @click="changeComponent('DefaultChat')"
            :class="{ 'selected': currentComponent === 'DefaultChat' }">
            ChatBot
          </button>
          <!--button class="option" 
              :class="{ 'selected': currentComponent === 'PreciseChat' }">
                Más preciso
              </button-->
        </div>
        <div>
          <MatrizIperc v-if="currentComponent === 'MatrizIperc'" />

          <DefaultChat v-if="currentComponent === 'DefaultChat'" :messages="chatMessages" :loading="loading" />

          <!--DefaultChat v-if="currentComponent === 'PreciseChat'" /-->
        </div>

      </div>
      <div class="footer">
        <div class="mensaje">
          <form @submit.prevent="handleSubmit">
            <input type="text" v-model="prompt" placeholder="Preguntame cualquier cosa..." />
            <input type="hidden" v-model="selectedConversationId" />
            <button class="send-button" type="submit">
              <img class="imagen" src="@/assets/img/send.png" alt="send" />
            </button>
          </form>
        </div>
        <div class="options">
          <div class="img file-icon" @click="toggleIconContainer">
            <img src="@/assets/img/attach_file.png" alt="file" />
          </div>
          <div class="icon-container" :style="{ display: showIconContainer ? 'flex' : 'none' }">
            <div class="img-icon">
              <img src="@/assets/img/imagesmode.png" alt="image" />
            </div>
            <div class="img-icon">
              <img src="@/assets/img/location_on.png" alt="location" />
            </div>
            <div class="img-icon">
              <img src="@/assets/img/description.png" alt="document" />
            </div>
            <div class="img-icon">
              <img src="@/assets/img/account_box.png" alt="contacts" />
            </div>
            <div class="rombo"></div>
          </div>
          <div class="img">
            <img src="@/assets/img/photo_camera.png" alt="camera" />
          </div>
          <div class="img">
            <img src="@/assets/img/mic.png" alt="microfono" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import MatrizIperc from "./MatrizIperc.vue";
import DefaultChat from "./DefaultChat.vue";
import moment from "moment";
export default defineComponent({
  name: "ChatBox",
  components: {
    MatrizIperc,
    DefaultChat,
  },
  data() {
    return {
      currentComponent: "DefaultChat",
      showIconContainer: false,
      selectedConversationId: null,
      chatVisible: false,
      informationVisible: false,
      contextVisible: false,
      prompt: "",
      chatMessages: [],
      chatConversations: [],
      loading: false,
      request: null,
      historyExpanded: false,
      selectedHistoryConversationId: null,
    };
  },
  methods: {
    changeComponent(componentName) {
      this.currentComponent = componentName;
    },
    toggleIconContainer() {
      this.showIconContainer = !this.showIconContainer;
    },
    toggleChat() {
      this.chatVisible = !this.chatVisible;
    },
    toggleChatVisibility() {
      this.chatVisible = true;
    },
    handleButtonClick() {
      if (!this.chatVisible) {
        this.toggleChat();
        this.startNewChat();
      } else {
        this.toggleChat();
      }
    },
    toggleInformation() {
      this.informationVisible = !this.informationVisible;
    },
    toggleContext() {
      this.contextVisible = !this.contextVisible;
    },
    startNewChat() {
      // Hacer una llamada a la API para crear una nueva conversación
      this.$axios.post(`/chatbot/createConversation`)
        .then((response) => {
          // Obtener el ID de la nueva conversación
          const newChatId = response.data.id;

          // Crear un array para los mensajes de la nueva conversación
          const newChatMessages = this.chatMessages.map((message) => {
            // Asignar el ID de la nueva conversación a cada mensaje
            return {
              ...message,
              conversationId: newChatId,
            };
          });

          // Agrega una nueva conversación
          this.chatConversations.push({
            id: newChatId,
            messages: newChatMessages,
          });

          // Selecciona automáticamente la nueva conversación
          this.selectedConversationId = newChatId;

          // Agrega el mensaje de default
          this.chatMessages = [{
            from: 'bot', // El mensaje es del bot
            text: '¡Hola! Soy Ipssobot, te ayudaré en todas las dudas que tengas sobre los cursos, diplomas, exámenes, y más. Dime, ¿Cuál es tu consulta?', time: moment().format('hh:mm A')
          }];
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async handleSubmit() {

      // Agrega el mensaje del usuario al chat
      const userMessage = { text: this.prompt, from: 'user', time: moment().format('hh:mm A') };
      this.loading = true;
      this.chatMessages.push(userMessage);
      try {
        this.request = await this.$axios.post(`/chatbot/chat`, {
          queryResult: this.prompt,
          conversationId: this.selectedConversationId
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*' // Asegúrate de configurarlo de manera segura en producción
          }
        });

        const response = await this.request;
        this.chatMessages.push({ text: response.data.response, from: 'bot', time: moment().format('hh:mm A') });
        this.loading = false;
        // Clear the input field
        this.prompt = "";
      }
      catch (err) {
        console.error('Error:', err);
      }
    },

    toggleHistory() {
      this.$axios.get(`/chatbot/history`)
        .then((response) => {
          this.chatConversations = response.data.allChatHistory;
          this.historyExpanded = !this.historyExpanded;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    loadConversationToChat(conversation) {

      this.selectedHistoryConversationId = conversation.conversationId;

      // Fetch messages for the selected conversation using getChatHistory API
      this.$axios.get(`/chatbot/history/${this.selectedHistoryConversationId}`)
        .then((response) => {
          const chatHistory = response.data.chatHistory;
          if (chatHistory) {
            // Limpia el array de chatMessages
            this.chatMessages = chatHistory.map(message => ({
              text: message.content,
              from: message.role === 'assistant' ? 'bot' : 'user',
              time: moment(message.date_of_issue).locale("es").subtract(4, 'hours').fromNow(),
            }));
            this.historyExpanded = !this.historyExpanded;
          } else {
            console.error('No se encontraron mensajes para la conversación seleccionada.')
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

});
</script>
<style>
* {
  box-sizing: border-box;
}

.chat-bubble {
  position: absolute;
    bottom: 262px;
    right: 60px;
    width: 5.4375rem;
    height: 5.4375rem;
    flex-shrink: 0;
    border: 2px solid rgba(255, 233, 164, 0.98);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1000;
    box-shadow: 0px 4px 6px rgb(250, 213, 5);
    transition: background-color 0.3s ease;
}

.image {
  width: 6rem;
    /* height: 5.4375rem; */
    flex-shrink: 0;
    margin-left: 0px;
    object-fit: cover;
}

.image-button {
  border: none;
  /* Elimina el borde del botón */
  background: none;
  /* Elimina el fondo del botón */
  cursor: pointer;
  /* Cambia el cursor al pasar por encima del botón */
  padding: 0;
  /* Elimina el espacio interior del botón */
}

.help-bubble {
  position: absolute;
    bottom: 21rem;
    right: 139px;
    background-color: white;
    color: black;
    padding: 10px;
    border-radius: 10px 10px;
    cursor: pointer;
    z-index: 999;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.help-bubble p {
  margin: 0;
}

.chat-container {
    /* position: relative; */
    /* margin-right: -58px; */
    /* position: relative; */
    /* bottom: 28px; */
    right: 36px;
    width: 399.91px;
    /* margin: auto; */
    border-radius: 0.625rem 0.625rem 0rem 0rem;
    border: 1px solid rgba(255, 233, 164, 0.98);
    background: #fff;
    height: 756px;
    border: 1px solid #f8bc02;
    border-radius: 17px;
    margin: 0px auto;
}

/* **************** */
.header {
  font-family: "Anek Latin", sans-serif;
}

.header .header-icons {
  border-radius: 15px 15px 0px 0px;
  background: #f8bc02;
  display: flex;
  align-items: center;
  padding: 4px 20px;
  justify-content: space-between;
}

.header .header-icons_title {
  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
  margin-left: 15px;
}

.header .header-icons_img {
  display: flex;
}

.header .header-icons_img .icon {
  margin: 10px 5px;
  cursor: pointer;
}

.header .header-icons_img .icon img {
  width: 17.44px;
  height: 17.44px;
}

.header .header-icons_img .icon-historial img {
  width: 4.98px;
  height: 18.69px;
}

.header .header-icons_img .icon-cerrar img {
  width: 18.96px;
  height: 16.96px;
}

/*fin del header*/
.main {
  height: auto;
  /* Establece la altura deseada */
  overflow-y: auto;
  /* Agrega una barra de desplazamiento vertical cuando sea necesario */
}

/* Estilos para la barra de desplazamiento en navegadores webkit (Google Chrome) */
.main::-webkit-scrollbar {
  width: 8px;
  /* Ancho de la barra de desplazamiento */
}

/* Estilos para el riel de la barra de desplazamiento en navegadores webkit (Google Chrome) */
.main::-webkit-scrollbar-track {
  background-color: #f8bc02;
  /* Color de fondo del riel */
  border-radius: 4px;
  /* Radio de borde del riel */
}

/* Estilos para el thumb (manija) de la barra de desplazamiento en navegadores webkit (Google Chrome) */
.main::-webkit-scrollbar-thumb {
  background-color: orange;
  /* Color del thumb */
  border-radius: 4px;
  /* Radio de borde del thumb */
}

/* Estilos para la barra de desplazamiento en Firefox */
.main {
  scrollbar-width: thin;
  /* Ancho del scrollbar */
}

/* Estilos para el thumb (manija) de la barra de desplazamiento en Firefox */
.main {
  scrollbar-color: #f8bc02 rgb(245, 231, 231);
  /* Color del thumb y del riel respectivamente */
  position: relative;
  height: 704.74px;
}

.main-logo {
  font-family: "Anek Latin", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.main-logo .logo img {
  width: 69.87px;
  height: 69.87px;
  margin-right: 10px;
}

.main-logo .title {
  line-height: 4px;
  text-align: justify;
  color: black;
  font-weight: 700;
}

.main-logo .title p {
  font-size: 12px;
}

.main-logo .title h1 {
  font-size: 25px;
}

/* opciones de chatbot */
.main-options {
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  font-weight: bold;
  margin-top: 20px;
}

.main-options .option {
  font-family: "Anek Latin", sans-serif;
  font-size: 15px;
  align-items: center;
  background: #eeeeee;
  width: 108.39px;
  height: 52.32px;
  padding: 10px 50px;
  text-align: center;
  margin: 2px;
  border-radius: 10px;
  border: none;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-weight: 800;
  transition: background-color 0.3s;
}

.option.selected {
  background: #f8bc02;
  color: white;
  font-weight: 600;
}

/* Cambia el color al pasar el cursor */
.option:hover {
  background-color: #f8bc02;
  color: white;
  font-weight: 600;
}

/* Cambia el color cuando el botón está seleccionado */

.main-options .option:nth-child(2) {
  height: 54px;
}

.footer {
  font-family: "Anek Latin", sans-serif;
  background: #f8bc02;
  display: flex;
  justify-content: space-between;
  /*  padding: 0px 20px; */
  align-items: center;
  border-radius: 0px 0px 15px 15px;
  position: relative;
    bottom: 56px;
  right: 0px;
  width: 100%;
  height: 55.13px;
}

.footer .options {
  display: flex;
  cursor: pointer;
  margin: 0px 6px 0px -3px;
}

.footer .options .img {
  margin-left: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
}

.footer .options .img:hover {
  background: #bf9500;
  border-radius: 50%;
}

.footer .options img {
  width: 22.49px;
  height: 22.49px;
}

.footer .mensaje {
  display: flex;
  align-items: center;
  position: relative;
  width: 271px;
  margin: 0 auto;
}

.footer .mensaje input {
  width: 270.97px;
  height: 37.37px;
  padding: 0px 14px;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding-right: 40px;
  background-color: white;
}

.footer input:focus {
  outline: none;
}

.footer .mensaje ::placeholder {
  font-family: "Anek Latin", sans-serif;
  font-weight: 300;
  font-size: 15px;
  text-align: left;
  color: #b9b9b9;
}

.footer .mensaje img {
  position: absolute;
  cursor: pointer;
  left: 88%;
}

.footer .imagen {
  position: absolute;
  top: 50%;
  right: 10px;
  /* Posicionamiento a la derecha */
  transform: translateY(-50%);
  width: 20px;
  /* Ancho de la imagen */
  height: auto;
}

/* codigo nuevo */
/* Estilos para los iconos de opciones */
.footer .icon-container {
  position: absolute;
  bottom: 58px;
  background: rgb(191, 149, 0);
  height: 32.67px;
  width: 115px;
  right: 32px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer .icon-container img {
  width: 22.83px;
  height: 22.83px;
}

.rombo {
  background-color: transparent;
  position: absolute;
  top: 32px;
  left: 45px;
}

.rombo:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 13px solid transparent;
}

.rombo:after {
  border-top-color: rgb(191, 149, 0);
}

.chat-context {
  position: absolute;
  top: 5.4%;
  left: 57%;
  width: 7.5625rem;
  height: 5.1875rem;
  flex-shrink: 0;
  border-radius: 0.1875rem;
  border: 1px solid #d2d2d2;
  border-top: 1px solid #d2d2d2;
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 1;
  /* Add this line to set the stacking order */
}

.context-button {
  background: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  /* Centramos el texto horizontalmente */
  padding: 5px;
  /* Ajustamos el relleno */
  color: #545454;
  font-family: "Anek Latin", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.send-button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.history-panel {
  position: absolute;
  top: 0;
  right: -300px;
  /* Inicialmente, el panel de historial está oculto hacia la derecha */
  width: 230px;
  /* Ancho del panel de historial cuando se expande */
  height: 100%;
  /* Altura del panel de historial (ajusta según sea necesario) */
  background-color: #fff;
  /* Color de fondo del panel de historial */
  z-index: 1000;
  /* Asegura que esté en la parte superior de otros elementos */
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  /* Sombra suave a la izquierda */
  transition: right 0.3s ease;
  /* Transición al mostrar/ocultar el panel de historial */
  display: none;
}

.history-panel.expanded {
  display: block;
  /* Oculta el panel cuando está colapsado */
}

.chat-conversations {
  width: 23.25rem;
  max-width: 13.725rem;
  /* Establecer un ancho máximo */
  height: auto;
  max-height: 400px;
  /* Establecer una altura máxima deseada */
  overflow-y: auto;
  /* Agregar barra de deslizamiento vertical cuando sea necesario */
  flex-shrink: 0;
  background: #fff3ce;
  font-family: Segoe UI;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 5px;
  color: #363636;
  padding: 1px;
  border-radius: 5px;
  text-align: left;
  /* Cambia la alineación a "left" en lugar de "right" */
}

.close-history {
  width: 1.24681rem;
  height: 1.25rem;
  flex-shrink: 0;
  background: none;
  color: 000;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 90%;
  margin-top: 2%;
}

.close-history h2 {
  width: 5.25rem;
  height: 1.4375rem;
  flex-shrink: 0;
  color: #f8bc02;
  font-size: 1rem;
  font-family: "Anek Latin", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: -21px;
  /* Ajusta el valor según sea necesario */
  margin-right: -53px;
}

.expanded {
  right: 0;
  /* Muestra el panel de historial al desplazarlo hacia la izquierda */
}
</style>
