<template>
    <div class="modal-backdrop" v-if="isOpen" @click="close">
      <div class="modal-content">
        <slot></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'GlobalModal',
    props:{
      isOpen:Boolean,
    },
    
   emits:['updateModal'],
    methods: {
      
      close() {
        this.$emit('updateModal',false);
      }
    }
  }
  </script>
  <style scoped>
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
  flex-shrink: 0;
  max-width: 95%;
  overflow: auto;
  }
  </style>