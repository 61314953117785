<template>
  <div class="main-container matriz-iperc">
    <!-- style="display: none" -->
    <div class="ingresar-datos" v-show="showIngresarDatos">
      <input
        type="text"
        class="input-empresa"
        placeholder="Nombre de la empresa"
        v-model="newCorporation"
      />
      <input type="text" class="input-ruc" v-model="newRUC" placeholder="RUC" />
    </div>
    <div class="ingresar-proceso" v-show="showIngresarProceso">
      <button
        ref="processNameButton"
        class="process-name"
        contenteditable="true"
      >
        {{ processName }}
      </button>
      <button @click="openModal">+</button>
    </div>
    <!-- Modal -->
    <ContainedModal
      :isOpen="showProcessModal"
      @updateModal="showProcessModal = $event"
      :styleObject="{ width: '306px', height: '182px' }"
    >
      <div class="modal-content1">
        <h2>INGRESA UN NOMBRE PARA EL PROCESO</h2>
        <input
          type="text"
          v-model="newProcessName"
          placeholder="NOMBRE (PROCESO)"
        />
        <button @click="confirmProcessName" class="close">Listo</button>
      </div>
    </ContainedModal>
    <!-- Agregar Actividades -->
    <!-- Agregar el título "Actividades" y la tabla con botones -->
    <div class="actividades-container" v-show="showActividades">
      <div>
        <h2>ACTIVIDADES</h2>
        <button class="btn-agregar-actividad" @click="addActivity">+</button>
      </div>
      <table class="actividades-table">
        <tr v-for="(activity, index) in filteredActivities" :key="index" >
          <td>{{ activity.name }}</td>
          <td>
            <button class="btn-editar" @click="editActivity" :disabled="!activity.enabled">
              <!-- Corregido -->
              <img src="@/assets/img/editar.png" alt="editar" />
            </button>
          </td>
          <td>
            <button class="btn-eliminar" @click="deleteActivity(index)">
              <!-- Corregido -->
              <img src="@/assets/img/eliminar.png" alt="eliminar" />
            </button>
          </td>
        </tr>
      </table>
      <button class="btn-ejecutar" @click="processResults" :disabled="allButtonsDisabled">Ejecutar</button>
    </div>
  <!--div-->
  <!-- Formulario para llenar datos de la tarea -->
  <div class="nuevo-contenedor" v-show="showTarea">
    <div class="regresar">
      <button class="btn-regresar" @click="goBack">
        <!-- Corregido -->
        <div><img src="@/assets/img/flecha.png" alt="" /></div>
        <div>Regresar a ACTIVIDADES</div>
      </button>
    </div>
    <div class="nuevo-contenido">
      <button @click="mostrarFormularioEvaluacion" class="btn-crear-evaluacion" :disabled="!isTaskFormFilled">Crear evaluación de riesgo</button>
      <button class="btn-crear-control" @click="mostrarFormularioControl" :disabled="!isControlFormFilled">Crear control operativo</button>
      <ActivityForm @activity="handleActivity" :cleanAll="cleanAll"></ActivityForm>
    </div>
  </div>
  <!-- Formulario para la evaluación de riesgo -->
  <div class="nuevo-contenedor formulario-riesgo" v-show="showEval">
    <div class="regresar">
      <button class="btn-regresar" @click="goBack">
        <div><img src="@/assets/img/flecha.png" alt="" /></div>
        <div>Regresar a ACTIVIDAD</div>
      </button>
    </div>
    <div class="nuevo-contenido">
      <EvaluationForm @risk-evaluation="handleEvaluationRisk" :cleanAll="cleanAll" :risk="risks_id">
      </EvaluationForm>
    </div>
  </div>
  <div class="nuevo-contenedor formulario-riesgo" v-show="showControl">
    <div class="regresar">
      <button class="btn-regresar" @click="goBack">
        <div><img src="@/assets/img/flecha.png" alt="" /></div>
        <div>Regresar a ACTIVIDAD</div>
      </button>
    </div>
    <div class="nuevo-contenido">
      <ControlForm @operational-control="handleOperationalControl" :cleanAll="cleanAll">
      </ControlForm>
    </div>
  </div>
  <IpercResult :matrix_id="matrix_id" 
  :newProcessName="newProcessName" 
  :newRUC="newRUC" 
  :newCorporation="newCorporation"
  v-if="showResult"></IpercResult> 
</div>
  
</template>

<script>
import ContainedModal from "./ContainedModal.vue";
import ControlForm from "./ControlForm.vue";
import IpercResult from "./IpercResult.vue";
import EvaluationForm from "./EvaluationForm.vue";
import ActivityForm from "./ActivityForm.vue";
export default {
  name: "MyComponent",
  components: {
    ContainedModal,
    ControlForm,
    IpercResult,
    EvaluationForm,
    ActivityForm
  },
  data() {
    return {
      newCorporation: "",
      newRUC: "",
      matrix_id: 0,
      processes_id: 0,
      newProcessName: "",
      processName: "PROCESO",  
      showIngresarDatos: true,
      showIngresarProceso: true,
      showIPSSOBOTModal: false,
      showProcessModal: false,
      showResult: false,
      showTarea: false,
      showLugarModal: false,
      showActividades: false,
      showNuevoContenedor: false,
      showEval: false,
      showControl: false,
      previousView: "",
      risks_id: 0,
      places_id:0,
      tasks_id: 0,
      work_positions_id: 0, 
      dangers_id: 0,
      consequences_id: 0,
      iperc_id: 0,
      operationalControl: null,
      evaluationRisk: null,
      iperc: null,
      cleanAll: false,
      activities: [],
      lastEditButton: null,
    };
  },
  methods: {
   
    handleActivity(value){
      this.iperc = value;
      Promise.all([
        this.createTask(this.iperc.newTask),
        this.createDanger(this.iperc.newDanger),
        this.createRisk(this.iperc.newRisk),
        this.createConsequence(this.iperc.newConsequence),
        ]).then(() => {
          const iperx = {matrix_id: this.matrix_id,places_id: this.iperc.selectedLugar, processes_id: this.processes_id,
            tasks_id: this.tasks_id, work_positions_id: this.iperc.selectedPosicion, risks_id: this.risks_id, dangers_id: this.dangers_id,
            consequences_id: this.consequences_id, type: this.iperc.selectedType, routine: (this.iperc.routine===true) ? "Y": "N"};
            
            this.createIPERC(iperx);
        }).catch(error => {
          // Manejar errores si alguna de las funciones falla
          console.error('Error:', error);
        });
    },
    createIPERC(iperc){
      this.$axios.post(`/chatbot/iperc`, iperc)
        .then(response => {
          this.iperc_id = response.data.iperc.id;
          console.log(response.data.message);
        })
        .catch(error => {
          console.error('Error al crear la relación IPERC:', error);
        });
    },
    createConsequence(consequence){
      return new Promise((resolve,reject) => {
        this.$axios.post(`/chatbot/consequence`, {
        description: consequence
      })
        .then(response => {
          this.consequences_id = response.data.consequence.id;
          console.log(response.data.message);
          resolve();
        })
        .catch(error => {
          console.error('Error al crear la consecuencia:', error);
          reject(error);
        });
      });
      
    },
    createRisk(risk){
      return new Promise((resolve, reject) => {
        this.$axios.post(`/chatbot/risk`, {
        description: risk
      })
        .then(response => {
          this.risks_id = response.data.risk.id;
          console.log(response.data.message);
          resolve();
        })
        .catch(error => {
          console.error('Error al crear el riesgo:', error);
          reject(error);
        });
      });
      
    },
    createTask(task){
      return new Promise((resolve, reject) => {
        this.$axios.post(`/chatbot/task`, {
        description: task
      })
        .then(response => {
          this.tasks_id = response.data.task.id;
          console.log(response.data.message);
          resolve();
        })
        .catch(error => {
          console.error('Error al crear la tarea:', error);
          reject(error);
        });
      });
      
    },
    createDanger(danger){
      return new Promise((resolve, reject) =>{
        this.$axios.post(`/chatbot/danger`, {
        description: danger
      })
        .then(response => {
          this.dangers_id = response.data.danger.id;
          console.log(response.data.message);
          resolve();
        })
        .catch(error => {
          console.error('Error al crear el peligro:', error);
          reject(error);
        });
      });
      
    },
    handleEvaluationRisk(value){
      this.evaluationRisk = value;
      const evluationRisk = {risks_id: this.risks_id, index_a: this.evaluationRisk.index_a,
      index_b: this.evaluationRisk.index_b, index_c: this.evaluationRisk.index_c, index_d: this.evaluationRisk.index_d,
      severity: this.evaluationRisk.severity, signification: (this.evaluationRisk.signification===true ? 1:0), type: this.evaluationRisk.newRiskType};
      this.createEvaluationRisk(evluationRisk);
    },
    createEvaluationRisk(ev){
      this.$axios.post(`/chatbot/risk-evaluation`, ev)
        .then(response => {
          console.log(response.data.message);
        })
        .catch(error => {
          console.error('Error al crear la evaluación de riesgo asociado:', error);
          
        });
    },
    addActivity(){
      this.cleanAll = false;
      const nextIndex = this.activities.length + 1;
      this.activities.push({
        id: nextIndex,
        name: `Actividad ${nextIndex}`,
        enabled: true, 
      });
    },
    editActivity() {
      this.previousView = "actividades";
      this.showIngresarDatos = false;
      this.showIngresarProceso = false;
      this.showActividades = false;
      this.showTarea = true;     
    },
    deleteActivity(index){  
      this.activities.splice(index, 1);
    },
    handleOperationalControl(value) {
      this.operationalControl = value;
      const control = {iperc_id: this.iperc_id, elimination: this.operationalControl.eliminacion, sustitution: this.operationalControl.sustitucion,
        engineering_control: this.operationalControl.controlIngenieria, admin_control: this.operationalControl.controlAdministrativo, epps: this.operationalControl.EPPs};
      this.createOperationalControl(control);
    },
    createOperationalControl(cont){
      this.$axios.post(`/chatbot/operational-control`, cont)
        .then(response => {
          console.log(response.data.message);
        })
        .catch(error => {
          console.error('Error al crear el control operacional:', error);
          
        });
    },
    mostrarFormularioEvaluacion() {
      this.previousView="tarea";
      this.showEval = true;
      this.showTarea = false;
    },
    mostrarFormularioControl() {
      this.previousView="tarea";
      this.showControl = true;
      this.showTarea = false;
    },
    processResults(){
      this.showIngresarDatos = false;
      this.showIngresarProceso = false;
      this.showActividades = false;
      this.showResult=true;
    },
    createMatrix() {
      this.$axios.post(`/chatbot/matrix`, {
        corporation: this.newCorporation,
        ruc: this.newRUC,
      })
        .then(response => {
          this.matrix_id = response.data.matrix.id;
          console.log(response.data.message);
        })
        .catch(error => {
          console.error('Error al crear la matriz:', error);
          
        });
    },
    openModal() {
      this.showProcessModal = true;
    },
    confirmProcessName() {
      const processName = this.newProcessName.trim();
      if (processName !== "") {
        this.createProcess();
        this.createMatrix();
        this.processName = processName.toUpperCase();
        this.showProcessModal = false;
        this.activities.push({id: 1, name: 'Actividad 1', enabled: true})
        this.showActividades = true;
      } else {
        alert("Por favor ingrese un nombre para el proceso.");
      }
    },
    createProcess() {
      this.$axios.post(`/chatbot/process`, {name: this.newProcessName})
        .then(response => {
          this.processes_id = response.data.process.id;
          console.log(response.data.message);
        })
        .catch(error => {
          console.error('Error al crear el proceso:', error);
        });
    },
    
    goBack() {
      if (this.previousView === "actividades") {
        this.showIngresarDatos = true;
        this.showIngresarProceso = true;
        this.showActividades = true;
        this.showTarea = false;
        this.risks_id= 0,
        this.places_id = 0;
        this.tasks_id = 0;
        this.work_positions_id = 0;
        this.dangers_id = 0;
        this.consequences_id = 0;
        this.iperc_id = 0;
        this.operationalControl = null;
        this.evaluationRisk = null;
        this.iperc = null;
        this.cleanAll = true;
        this.activities.forEach(activity => {
          activity.enabled = false;
        });
      }else if(this.previousView==="tarea"){
        this.previousView="actividades";
        this.showIngresarDatos = false;
        this.showIngresarProceso = false;
        this.showActividades = false;
        this.showEval=false;
        this.showControl=false;
        this.showTarea = true;
      }
    },
  },
  computed: {
    filteredActivities() {
      return this.activities.filter(activity => !activity.deleted);
    },
    isTaskFormFilled(){
      return this.iperc_id !== 0;
    },
    isControlFormFilled(){
      return this.evaluationRisk !== null;
    },
    allButtonsDisabled() {
      return this.filteredActivities.some(activity => activity.enabled);
    },
  },
};
</script>



<style scoped>
/* matriz iperc */
.matriz-iperc .ingresar-datos {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.ingresar-datos input {
  border: 1.5px solid #f8bc02;
  border-radius: 5px;
}

.ingresar-datos input:focus {
  outline: none;
}

.ingresar-datos input::placeholder {
  font-family: "Anek Latin", sans-serif;
  font-weight: 300;
  font-size: 15px;
  text-align: left;
  color: #b9b9b9;
  padding: 0px 10px;
}

.matriz-iperc .ingresar-datos input:nth-child(1) {
  width: 220px;
  height: 30px;
  margin: 2px;
}

.matriz-iperc .ingresar-datos input:nth-child(2) {
  width: 108px;
  height: 30px;
  margin: 2px;
}

.ingresar-proceso {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.ingresar-proceso button {
  font-family: "Anek Latin", sans-serif;
  font-weight: 600;
  font-size: 20px;
  border: 1.5px solid #f8bc02;
  background: #ffffff;
  color: #f8bc02;
  border-radius: 8px;
  cursor: pointer;
}

.ingresar-proceso button:nth-child(1) {
  width: 154.85px;
  height: 40.4px;
  margin: 2px;
}

.ingresar-proceso button:nth-child(2) {
  width: 38.47px;
  height: 40.4px;
  margin: 2px;
  font-weight: 800;
}

/* modal de nombre de proceso */
.modal-content1 {
  background: #f8bc02;
  width: 306px;
  height: 182px;
  text-align: center;

  border-radius: 20px;
  display: flex;
  border: 3px solid white;
  padding: 13px 0px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: space-between;
}

.modal-content1 h2 {
  font-size: 15px;
  font-family: "Anek Latin", sans-serif;
  font-weight: 500;
  color: white;
  width: 140px;
  height: auto;
  margin-top: 11px;
  text-align: center;
}

.modal-content1 input {
  border: 1px solid #b9b9b9;
  width: 254px;
  height: 40px;
  border-radius: 10px;
  margin-top: -41px;
  background-color: white;
}

.modal-content1 input::placeholder {
  font-family: "Anek Latin", sans-serif;
  text-align: center;
  font-size: 20px;
  color: black;
  font-weight: 700;
}

.modal-content1 input:focus {
  outline: none;
}

.modal-content1 button {
  border: 2px solid white;
  background: #f8bc02;
  margin-top: 21px;
  width: 93px;
  height: 30px;
  border-radius: 10px;
  color: white;
  font-family: "Anek Latin", sans-serif;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  margin-top: -40px;
  opacity: 1;
}

/* vista de las actividades */
.actividades-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 363px;
  position: relative;
}

.actividades-container div {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.actividades-container h2 {
  font-family: "Anek Latin", sans-serif;
  font-weight: 600;
  font-size: 15px;
  margin: 3px;
}

.actividades-container .btn-agregar-actividad {
  margin: 3px;
  border: 1px solid #f8bc02;
  border-radius: 10px;
  background: white;
  font-weight: 900;
  color: #f8bc02;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.actividades-container .actividades-table {
  margin-top: 14px;
}

.actividades-container .actividades-table td {
  border: 1px solid #f8bc02;
}

.actividades-container .actividades-table td:nth-child(1) {
  width: 276px;
  height: 26px;
  text-align: center;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 700;
}

.actividades-container .actividades-table button:hover {
  background: #f8bc02;
}

.actividades-container .btn-editar {
  width: 26px;
  height: 26px;
  background: white;
  border: none;
  cursor: pointer;
}

.actividades-container .btn-editar img {
  width: 13.13px;
  height: 15.01px;
}

.actividades-container .btn-editar:disabled{
  background: #f8bc02;
  opacity: 0.5;
}

.actividades-container .btn-ejecutar {
  background: #f8bc02;
  position: absolute;
  bottom: 35px;
  border-radius: 5px;
  color: #fff;
  width: 204px;
  height: 40px;
  font-family: "Anek Latin", sans-serif;
  font-size: 20px;
  font-weight: 600;
  /* pointer-events: none;
  cursor: not-allowed; */
  border: none;
}

.actividades-container .btn-ejecutar:disabled {
  opacity: 0.5;
}

.actividades-container .btn-eliminar {
  background: white;
  border: none;
  cursor: pointer;
  width: 26px;
  height: 26px;
}

.actividades-container .btn-eliminar img {
  width: 13.13px;
  height: 13.13px;
}

/* registro de editar */
.nuevo-contenedor {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Anek Latin", sans-serif;
  font-size: 15px;
  margin-top: -20px;
}

.nuevo-contenedor .btn-regresar {
  width: 338px;
  display: flex;
  font-family: "Anek Latin", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #f8bc02;
  background: white;
  border: none;
  justify-content: flex-start;
  margin: 12px 0px;
  cursor: pointer;
}

.nuevo-contenedor .btn-regresar img {
  margin-right: 8px;
}

.nuevo-contenido {
  font-family: "Anek Latin", sans-serif;
  font-size: 15px;
}

.nuevo-contenido button{
  width: 161px;
  height: 48px;
  font-family: "Anek Latin", sans-serif;
  font-size: 15px;
  color: white;
  background: #f8bc02;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  padding: 0px 20px;
  margin-bottom: 10px;
  margin-right: 4px;
  cursor: pointer;
}

.nuevo-contenido button:disabled {
  opacity: 0.5;
}

.nuevo-contenido form {
  display: flex;
  flex-direction: column;
  width: 332px;
  line-height: 22px;
  text-align: left;
}

.nuevo-contenido form input {
  width: 332px;
  height: 25px;
  background: #eeeeee;
  border: 1px solid #ababab;
  border-radius: 4px;
}

.nuevo-contenido input::placeholder {
  font-family: "Anek Latin", sans-serif;
  font-weight: 300;
  font-size: 15px;
  text-align: left;
  color: #656565;
  padding: 0px 10px;
}

.nuevo-contenido form input:focus {
  outline: none;
}

.nuevo-contenido form label {
  font-weight: 600;
}

.nuevo-contenido .lugar-posicion {
  display: flex;
}

.nuevo-contenido .lugar-posicion .lugar {
  margin-right: 10px;
}

.nuevo-contenido .lugar-posicion select {
  width: 161px;
  height: 25px;
  background: #eeeeee;
  border: 1px solid #ababab;
  border-radius: 4px;
}

.nuevo-contenido select:focus {
  outline: none;
}

.nuevo-contenido .lugar-posicion input {
  width: 161px;
  height: 25px;
}

.nuevo-contenido .tipo {
  width: 332px;
  height: 25px;
  background: #eeeeee;
  border: 1px solid #ababab;
  border-radius: 4px;
}

.nuevo-contenido form .enviar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 9px;
}

.nuevo-contenido .enviar button {
  height: 32px;
  width: 121px;
}

.nuevo-contenido .enviar button:disabled {
  opacity: 0.5;
}


.nuevo-contenido .enviar label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.nuevo-contenido .enviar input {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

/* formulario riesgo */
.formulario-riesgo h3 {
  font-family: "Anek Latin", sans-serif;
  font-weight: 800;
  font-size: 15px;
  margin: 0px;
}

.formulario-riesgo label {
  font-family: "Anek Latin", sans-serif;
  font-weight: 600;
  font-size: 15px;
}

.formulario-riesgo select {
  width: 332px;
  height: 25px;
  background: #eeeeee;
  border: 1px solid #ababab;
  border-radius: 4px;
  margin-bottom: 10px;
}

.formulario-riesgo .chekbox {
  display: flex;
  justify-content: center;
}

.formulario-riesgo .chekbox input {
  width: 14px;
  height: 18px;
}

.formulario-riesgo .botones {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formulario-riesgo .botones button {
  width: 121px;
  height: 40px;
}

.formulario-riesgo .botones button:nth-child(1) {
  background: white;
  color: black;
  display: flex;
  padding: 5px;
  border: 3px solid #f8bc02;
  align-items: center;
}

.formulario-riesgo .botones button:nth-child(2) {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.formulario-riesgo .botones button:nth-child(2).disabled {
  opacity: 0.5;
}
</style>
