<template>

    <div class="preview-container">
      <table>
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col" colspan="7">IDENTIFICACIÓN</th>
            <th scope="col" colspan="9">EVALUACIÓN DEL RIESGO</th>
            <th scope="col" colspan="5">CONTROL OPERACIONAL</th>
            <th scope="col" colspan="8">EVALUACIÓN DEL RIESGO</th>
          </tr>
          <tr>
            <th scope="col">N°</th>
            <th scope="col">Tarea</th>
            <th scope="col">Posición de trabajo</th>
            <th scope="col">Lugar</th>
            <th scope="col">Rutinario</th>
            <th scope="col">Peligro</th>
            <th scope="col">Tipo</th>
            <th scope="col">Riesgo</th>
            <th scope="col">Consecuencias</th>
            <th scope="col">Índice de Personas Expuestas (A)</th>
            <th scope="col">Índice de Procedimientos Existentes (B)</th>
            <th scope="col">Índice de Capacitación (C)</th>
            <th scope="col">Índice de Exposición al Riesgo (D)</th>
            <th scope="col">Índice de Probabilidad de Ocurrencia (A+B+C+D)</th>
            <th scope="col">Índice de Severidad (IS)</th>
            <th scope="col">Probabilidad x Severidad (P x S)</th>
            <th scope="col">Nivel de Riesgo (NV)</th>
            <th scope="col">Riesgo Significativo (RS)</th>
            <th scope="col">Eliminación</th>
            <th scope="col">Sustitución</th>
            <th scope="col">Control de Ingeniería</th>
            <th scope="col">Control Administrativo</th>
            <th scope="col">EPPS</th>
            <th scope="col">Índice de Personas Expuestas (A)</th>
            <th scope="col">Índice de Procedimientos Existentes (B)</th>
            <th scope="col">Índice de Capacitación (C)</th>
            <th scope="col">Índice de Exposición al Riesgo (D)</th>
            <th scope="col">Índice de Probabilidad de Ocurrencia (A+B+C+D)</th>
            <th scope="col">Índice de Severidad (IS)</th>
            <th scope="col">Probabilidad x Severidad (P x S)</th>
            <th scope="col">Nivel de Riesgo (NV)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(iperx, index) in matrix.iperc" :key="index">
            <th scope="row">{{ index + 1 }}</th>
            <td>{{ iperx.task ? iperx.task.description : 'N/A' }}</td>
            <td>{{ iperx.work_position ? iperx.work_position.name : 'N/A' }}</td>
            <td>{{ iperx.place ? iperx.place.name : 'N/A' }}</td>
            <td>{{ iperx.routine === 'Y' ? 'Rutinario' : 'No Rutinario' }}</td>
            <td>{{ iperx.danger ? iperx.danger.description : 'N/A' }}</td>
            <td>{{ iperx.type ? iperx.type : 'N/A' }}</td>
            <td>{{ iperx.risk ? iperx.risk.description : 'N/A' }}</td>
            <td>{{ iperx.consequence ? iperx.consequence.description : 'N/A' }}</td>
            <td>{{ iperx.risk.risk_evaluation[0].index_a !== null ? iperx.risk.risk_evaluation[0].index_a : 'N/A' }}</td>
            <td>{{ iperx.risk.risk_evaluation[0].index_b !== null ? iperx.risk.risk_evaluation[0].index_b : 'N/A' }}</td>
            <td>{{ iperx.risk.risk_evaluation[0].index_c !== null ? iperx.risk.risk_evaluation[0].index_c : 'N/A' }}</td>
            <td>{{ iperx.risk.risk_evaluation[0].index_d !== null ? iperx.risk.risk_evaluation[0].index_d : 'N/A' }}</td>
            <td>{{ iperx.risk.risk_evaluation[0].probability !== null ? iperx.risk.risk_evaluation[0].probability : 'N/A' }}</td>
            <td>{{ iperx.risk.risk_evaluation[0].severity !== null ? iperx.risk.risk_evaluation[0].severity : 'N/A' }}</td>
            <td>{{ iperx.risk.risk_evaluation[0].ps !== null ? iperx.risk.risk_evaluation[0].ps : 'N/A' }}</td>
            <td>{{ iperx.risk.risk_evaluation[0].NV !== null ? iperx.risk.risk_evaluation[0].NV : 'N/A' }}</td>
            <td>{{ iperx.risk.risk_evaluation[0].signification === 1 ? 'Sí' : 'No' }}</td>
            <td>{{ iperx.operational_controls[0].elimination !== null ? iperx.operational_controls[0].elimination : 'N/A' }}</td>
            <td>{{ iperx.operational_controls[0].sustitution !== null ? iperx.operational_controls[0].sustitution : 'N/A' }}</td>
            <td>{{ iperx.operational_controls[0].engineering_control !== null ? iperx.operational_controls[0].engineering_control : 'N/A' }}</td>
            <td>{{ iperx.operational_controls[0].admin_control !== null ? iperx.operational_controls[0].admin_control : 'N/A' }}</td>
            <td>{{ iperx.operational_controls[0].epps !== null ? iperx.operational_controls[0].epps : 'N/A' }}</td>
            <td>{{ iperx.risk.risk_evaluation[1].index_a !== null ? iperx.risk.risk_evaluation[1].index_a : 'N/A' }}</td>
            <td>{{ iperx.risk.risk_evaluation[1].index_b !== null ? iperx.risk.risk_evaluation[1].index_b : 'N/A' }}</td>
            <td>{{ iperx.risk.risk_evaluation[1].index_c !== null ? iperx.risk.risk_evaluation[1].index_c : 'N/A' }}</td>
            <td>{{ iperx.risk.risk_evaluation[1].index_d !== null ? iperx.risk.risk_evaluation[1].index_d : 'N/A' }}</td>
            <td>{{ iperx.risk.risk_evaluation[1].probability !== null ? iperx.risk.risk_evaluation[1].probability : 'N/A' }}</td>
            <td>{{ iperx.risk.risk_evaluation[1].severity !== null ? iperx.risk.risk_evaluation[1].severity : 'N/A' }}</td>
            <td>{{ iperx.risk.risk_evaluation[1].ps !== null ? iperx.risk.risk_evaluation[1].ps : 'N/A' }}</td>
            <td>{{ iperx.risk.risk_evaluation[1].NV !== null ? iperx.risk.risk_evaluation[1].NV : 'N/A' }}</td>
          </tr>
        </tbody>
      </table>
      <button class="expand-table" @click="openModal"><span >Ver completo</span><img :src="require('@/assets/img/expand-orange.svg')" alt="expand-table"></button>
      <GlobalModal :isOpen="isOpen" @updateModal="isOpen = $event">
        <table>
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col" colspan="7">IDENTIFICACIÓN</th>
              <th scope="col" colspan="9">EVALUACIÓN DEL RIESGO</th>
              <th scope="col" colspan="5">CONTROL OPERACIONAL</th>
              <th scope="col" colspan="8">EVALUACIÓN DEL RIESGO</th>
            </tr>
            <tr>
              <th scope="col">N°</th>
              <th scope="col">Tarea</th>
              <th scope="col">Posición de trabajo</th>
              <th scope="col">Lugar</th>
              <th scope="col">Rutinario</th>
              <th scope="col">Peligro</th>
              <th scope="col">Tipo</th>
              <th scope="col">Riesgo</th>
              <th scope="col">Consecuencias</th>
              <th scope="col">Índice de Personas Expuestas (A)</th>
              <th scope="col">Índice de Procedimientos Existentes (B)</th>
              <th scope="col">Índice de Capacitación (C)</th>
              <th scope="col">Índice de Exposición al Riesgo (D)</th>
              <th scope="col">Índice de Probabilidad de Ocurrencia (A+B+C+D)</th>
              <th scope="col">Índice de Severidad (IS)</th>
              <th scope="col">Probabilidad x Severidad (P x S)</th>
              <th scope="col">Nivel de Riesgo (NV)</th>
              <th scope="col">Riesgo Significativo (RS)</th>
              <th scope="col">Eliminación</th>
              <th scope="col">Sustitución</th>
              <th scope="col">Control de Ingeniería</th>
              <th scope="col">Control Administrativo</th>
              <th scope="col">EPPS</th>
              <th scope="col">Índice de Personas Expuestas (A)</th>
              <th scope="col">Índice de Procedimientos Existentes (B)</th>
              <th scope="col">Índice de Capacitación (C)</th>
              <th scope="col">Índice de Exposición al Riesgo (D)</th>
              <th scope="col">Índice de Probabilidad de Ocurrencia (A+B+C+D)</th>
              <th scope="col">Índice de Severidad (IS)</th>
              <th scope="col">Probabilidad x Severidad (P x S)</th>
              <th scope="col">Nivel de Riesgo (NV)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(iperx, index) in matrix.iperc" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ iperx.task ? iperx.task.description : 'N/A' }}</td>
              <td>{{ iperx.work_position ? iperx.work_position.name : 'N/A' }}</td>
              <td>{{ iperx.place ? iperx.place.name : 'N/A' }}</td>
              <td>{{ iperx.routine === 'Y' ? 'Rutinario' : 'No Rutinario' }}</td>
              <td>{{ iperx.danger ? iperx.danger.description : 'N/A' }}</td>
              <td>{{ iperx.type ? iperx.type : 'N/A' }}</td>
              <td>{{ iperx.risk ? iperx.risk.description : 'N/A' }}</td>
              <td>{{ iperx.consequence ? iperx.consequence.description : 'N/A' }}</td>
              <td>{{ iperx.risk.risk_evaluation[0].index_a !== null ? iperx.risk.risk_evaluation[0].index_a : 'N/A' }}</td>
              <td>{{ iperx.risk.risk_evaluation[0].index_b !== null ? iperx.risk.risk_evaluation[0].index_b : 'N/A' }}</td>
              <td>{{ iperx.risk.risk_evaluation[0].index_c !== null ? iperx.risk.risk_evaluation[0].index_c : 'N/A' }}</td>
              <td>{{ iperx.risk.risk_evaluation[0].index_d !== null ? iperx.risk.risk_evaluation[0].index_d : 'N/A' }}</td>
              <td>{{ iperx.risk.risk_evaluation[0].probability !== null ? iperx.risk.risk_evaluation[0].probability : 'N/A' }}</td>
              <td>{{ iperx.risk.risk_evaluation[0].severity !== null ? iperx.risk.risk_evaluation[0].severity : 'N/A' }}</td>
              <td>{{ iperx.risk.risk_evaluation[0].ps !== null ? iperx.risk.risk_evaluation[0].ps : 'N/A' }}</td>
              <td>{{ iperx.risk.risk_evaluation[0].NV !== null ? iperx.risk.risk_evaluation[0].NV : 'N/A' }}</td>
              <td>{{ iperx.risk.risk_evaluation[0].signification === 1 ? 'Sí' : 'No' }}</td>
              <td>{{ iperx.operational_controls[0].elimination !== null ? iperx.operational_controls[0].elimination : 'N/A' }}</td>
              <td>{{ iperx.operational_controls[0].sustitution !== null ? iperx.operational_controls[0].sustitution : 'N/A' }}</td>
              <td>{{ iperx.operational_controls[0].engineering_control !== null ? iperx.operational_controls[0].engineering_control : 'N/A' }}</td>
              <td>{{ iperx.operational_controls[0].admin_control !== null ? iperx.operational_controls[0].admin_control : 'N/A' }}</td>
              <td>{{ iperx.operational_controls[0].epps !== null ? iperx.operational_controls[0].epps : 'N/A' }}</td>
              <td>{{ iperx.risk.risk_evaluation[1].index_a !== null ? iperx.risk.risk_evaluation[1].index_a : 'N/A' }}</td>
              <td>{{ iperx.risk.risk_evaluation[1].index_b !== null ? iperx.risk.risk_evaluation[1].index_b : 'N/A' }}</td>
              <td>{{ iperx.risk.risk_evaluation[1].index_c !== null ? iperx.risk.risk_evaluation[1].index_c : 'N/A' }}</td>
              <td>{{ iperx.risk.risk_evaluation[1].index_d !== null ? iperx.risk.risk_evaluation[1].index_d : 'N/A' }}</td>
              <td>{{ iperx.risk.risk_evaluation[1].probability !== null ? iperx.risk.risk_evaluation[1].probability : 'N/A' }}</td>
              <td>{{ iperx.risk.risk_evaluation[1].severity !== null ? iperx.risk.risk_evaluation[1].severity : 'N/A' }}</td>
              <td>{{ iperx.risk.risk_evaluation[1].ps !== null ? iperx.risk.risk_evaluation[1].ps : 'N/A' }}</td>
              <td>{{ iperx.risk.risk_evaluation[1].NV !== null ? iperx.risk.risk_evaluation[1].NV : 'N/A' }}</td>
            </tr>
          </tbody>
        </table>
        
      </GlobalModal>
    </div>
   
  
  </template>
    
  <script>
  import GlobalModal from './GlobalModal.vue';
  export default {
      name: 'IpercTabla',
      components:{
        GlobalModal
      },
      props: {
        matrix_id: Number,
      },
      data() {
          return {
            isOpen:false,
            matrix: {},
          };
      },
      created(){
        this.fetchMatrix();
      },
      methods: {
        openModal(){
          this.isOpen=true
        },
        fetchMatrix() {
          return new Promise((resolve,reject) => {
            this.$axios.get(`/chatbot/matrix/${this.matrix_id}`)
            .then(response => {
              this.matrix = { ...this.matrix, ...response.data };
              resolve();
            })
            .catch(error => {
              reject(error);
            });
          });
        
        },
      },
  };
  </script>
    
  <style scoped>
  
  button span{
    font-size:15px;
  }
  
  button{
    display: flex;
    align-self: center;
    column-gap: 5px;
  }
  .expand-table{
    background: none;
    border: none;
    cursor: pointer;
    
  }
  .expand-table span{
    color: #F8BC02;
    font-weight: bold;
    
  }
  
  .preview-container{
    width: 100%;
    max-height: 260px; 
    flex-shrink: 0;
    overflow:auto;
  }
  
  ::-webkit-scrollbar {
      width: 5px;
      height: 7.5px;
      border-radius: 5px;
      background: #EBEBEB;
  }
  
  ::-webkit-scrollbar-button {
      display: none;
  }
  
  ::-webkit-scrollbar-thumb {
      background: #BABABA; 
      border-radius: 50px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
      background: #555; 
  }
  table{
    color:black;
  }
  table, th, td {
    border: 1px solid #BABABA;
    border-collapse: collapse;
    text-align: center;
  }
  th{
    background-color: #EBEBEB;
  }
  td,th{
    min-width: 60px;
    padding: 5px;
  }
  tr>td:first-child{
    background-color: #EBEBEB;
  }
  </style>
    